import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Values, EditButton } from '../../common/index';
import { useLazyQuery } from '@apollo/client';
import { statOrdersByAssociationQuery } from '../../queries/queries';

function Association({ association, setEditing, statDates }) {
  const [getOrderStats, { data: orderStats }] = useLazyQuery(statOrdersByAssociationQuery);

  useEffect(() => {
    getOrderStats({
      variables: {
        associationId: association._id,
        fromDate: statDates.fromDate,
        toDate: statDates.toDate
      }
    });
  }, [statDates]);

  return (
    <tr key={association._id}>
      <td>
        <p className='m-0'>{association.name}</p>
        {association.adminInvitations?.filter(admin => admin.userIdActivated === null).length > 0 &&
          <span className='small inactive'
            title={association.adminInvitations?.filter(admin => admin.userIdActivated === null).map(admin => admin.sentToEmail)}
          >
            Inväntar {association.adminInvitations?.filter(admin => admin.userIdActivated === null).length} administratörers aktivering...
          </span>
        }
      </td>
      <td><Values values={association.adminEmails} /></td>
      <td>{association.note}</td>
      <td>
        <EditButton size="xs" setEditing={() => setEditing(association._id)}>Redigera</EditButton>
        <Link className="secondary xs arrow" to={`/cities/associations/${association._id}/teams`}>Lag</Link>
      </td>
      <td>
        <span>{orderStats?.orders?.totalUsedOrders ?? 0}/{orderStats?.orders?.totalNumber ?? 0}</span>
      </td>
    </tr>
  )
}

export default Association
