import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import { addAgreementToAssociationMutation, updateAgreementInAssociationMutation, getAssociationQuery, getPeriodsQuery } from '../../queries/queries';
import { Form, Field, Group } from '../../common/index';

function AssociationAgreementsForm(props) {
  const { content, onComplete } = props;
  const [sContent, setSContent] = useState(content);

  const { data: periods, loading } = useQuery(getPeriodsQuery);

  const ss = (c) => setSContent({ ...sContent, ...c });

  return (
    <tr key={content ? content._id : 'new'} className="open">
      <td colSpan="100">
        <Form sContent={sContent} parent="associationId" get={getAssociationQuery} add={addAgreementToAssociationMutation} update={updateAgreementInAssociationMutation} onComplete={onComplete}>
          <Group title="Inställningar">
            <Field type="selectPeriod" req name="activeFrom" title="Från period" value={sContent && sContent.activeFrom} selectDataInfo={periods} isDataLoading={loading} selectData={periods?.periods} onChange={ss} />
            <Field type="selectPeriod" req name="activeTo" title="Till och med period" value={sContent && sContent.activeTo} selectDataInfo={periods} isDataLoading={loading} selectData={periods?.periods} onChange={ss} />
          </Group>
        </Form>
      </td>
    </tr>
  );
}

AssociationAgreementsForm.propTypes = {
  content: PropTypes.object.isRequired,
  onComplete: PropTypes.func.isRequired,
};

export default AssociationAgreementsForm;
