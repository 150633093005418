import React, { useEffect, useState } from 'react'
import { useQuery } from '@apollo/client';
import { getCompanyTypesQuery } from '../../queries/company';
import { usePappersHafteContext } from '../../context/PappersHafteContext';
import CompanyCoupon from './CompanyCoupon';
import PageWrapper from './PageWrapper';

function CompanyCoupons() {
  const { booklet, tableOfContent } = usePappersHafteContext();
  const [companyCoupons, setCompanyCoupons] = useState([]);
  const { data: companyTypesData } = useQuery(getCompanyTypesQuery);

  useEffect(() => {
    if (tableOfContent) {
      // Flatten tableOfContent and extract companies
      const companiesWithCategory = tableOfContent.flat().map(page => page.companies).flat();

      // Extract all coupons from each company
      const couponsWithCategory = companiesWithCategory.flatMap(company =>
        company.calculatedCoupons.map(coupon => ({ ...coupon, company: company }))
      );

      setCompanyCoupons(couponsWithCategory);
    }
  }, [tableOfContent]);

  return (
    <div>
      {companyCoupons.map((coupon) => (
        <>
          <CompanyCoupon
            key={coupon._id}
            booklet={booklet}
            company={coupon.company}
            coupon={coupon}
            companyType={companyTypesData?.types[coupon.company.typeId]}
          />
          {/* Blank page */}
          {/* <PageWrapper /> */}
        </>
      ))}
    </div>
  )
}

export default CompanyCoupons;
