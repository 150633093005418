import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import { statOrdersByTeamQuery, statOrdersByTeamMemberQuery, statTeamTopTeamMembersQuery } from '../../../queries/queries';
import { StatsSection, StatsBox, StatsListItem, StatsNumber } from '../../../common/index';

// function statsVariables(associationId, periods, currentPeriod) {
//   return {
//     variables: {
//       associationId,
//       fromPeriod: currentPeriod === 'all' ? (periods && periods.fromPeriod) : currentPeriod,
//       toPeriod: currentPeriod === 'all' ? (periods && periods.toPeriod) : currentPeriod,
//     },
//   };
// }

function SalesStatistics(props) {
  const { teamId, teamMemberId } = props;

  const [statDates, setStatDates] = useState({ fromDate: null, toDate: null });

  //const [period, setPeriod] = useState();
  //const statsVars = statsVariables(teamMemberId, agreementPeriods, period);
  /*const { data: agreementPeriods } = useQuery(ongoingPeriodsInAssociationQuery, { 
    variables: { 
      associationId 
    } 
  });
  
  React.useEffect(() => {
    //agreementPeriods && setPeriod(agreementPeriods.periods.currentPeriod)
  }, [agreementPeriods])
  */

  const { data: statsOrderTeams } = useQuery(statOrdersByTeamQuery, {
    variables: {
      teamId,
      fromDate: statDates.fromDate ? statDates.fromDate : null,
      toDate: statDates.toDate ? statDates.toDate : null
    }
  });
  const { data: statsOrderTeamMember } = useQuery(statOrdersByTeamMemberQuery, {
    variables: {
      teamMemberId,
      fromDate: statDates.fromDate ? statDates.fromDate : null,
      toDate: statDates.toDate ? statDates.toDate : null
    }
  });
  const { data: statsTeamTopTeamMembers } = useQuery(statTeamTopTeamMembersQuery, {
    variables: {
      teamId,
      fromDate: statDates.fromDate ? statDates.fromDate : null,
      toDate: statDates.toDate ? statDates.toDate : null
    }
  });

  return (
    <StatsSection /*agreementPeriods={agreementPeriods} currentPeriod={period} setPeriod={(p) => setPeriod(p)}*/ setStatDates={setStatDates} statDates={statDates}>
      <StatsBox type="number" title="Min försäljning" corner="icon superb">
        {statsOrderTeamMember?.orders?.totalNumber > 0
          ? <StatsNumber value={statsOrderTeamMember.orders.totalNumber} />
          : <p className='no-sales'>Inga häften sålda under denna period.</p>
        }

      </StatsBox>
      <StatsBox type="number" title="Lagets försäljning" corner="icon hf">
        {statsOrderTeamMember?.orders?.totalNumber > 0
          ? <StatsNumber value={statsOrderTeams && statsOrderTeams.orders && statsOrderTeams.orders.totalNumber} />
          : <p className='no-sales'>Inga häften sålda under denna period.</p>
        }

      </StatsBox>
      <StatsBox type="list" title="Säljtoppen (i laget)" corner="icon leader">
        {statsOrderTeamMember?.orders?.totalNumber > 0
          ? <>
            {statsTeamTopTeamMembers && statsTeamTopTeamMembers.teamMembers.teamMembers.map((teamMember) => (
              <StatsListItem key={teamMember.teamMember._id} title={`${teamMember.teamMember.firstname} ${teamMember.teamMember.lastname}`} value={teamMember.total} />
            ))}
          </>
          : <p className='no-sales'>Inga häften sålda under denna period.</p>
        }

      </StatsBox>
      {/* <StatsBox type="amount" title="Laget har tjänat" corner="icon cash">
        {statsOrderTeamMember?.orders?.totalNumber > 0
          ? <StatsAmount value={statsOrderTeams && statsOrderTeams.orders && statsOrderTeams.orders.totalAmount} />
          : <p className='no-sales'>Inga häften sålda under denna period.</p>
        }        
      </StatsBox> */}
    </StatsSection>
  );
}

SalesStatistics.propTypes = {
  associationId: PropTypes.string.isRequired,
  teamId: PropTypes.string.isRequired,
  teamMemberId: PropTypes.string.isRequired,
};

SalesStatistics.defaultProps = {
};

export default SalesStatistics;
