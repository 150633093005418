import React, { useEffect, useState } from 'react'
import { Button, PageHeader } from '../../../common'
import { useQuery } from '@apollo/client';
import { getTeamQuery } from '../../../queries/team';
import { getAssociationPublicQuery } from '../../../queries/association';
import Onboarding from '../Onboarding';
import PersonalWebshops from '../../../img/shop_users.svg';
import One from '../../../img/1.svg';
import Two from '../../../img/2.svg';
import Three from '../../../img/3.svg';
import HowTo from '../HowTo';
import { useNavigationContext } from '../../../context/NavigationContext';

const { REACT_APP_BASE_URL } = process.env;
export default function DashboardTeamHome(props) {
  const { association: associationId, team: teamId } = props.match.params;
  const { data: associationData } = useQuery(getAssociationPublicQuery, { variables: { associationId: associationId } });
  const { data: teamData } = useQuery(getTeamQuery, { variables: { teamId: teamId } });

  const [isTextCopied, setIsTextCopied] = useState(false);
  const [isLinkCopied, setIsLinkCopied] = useState(false);
  const [invitationLink, setInvitationLink] = useState();

  const { setNavigationActivated } = useNavigationContext();
  useEffect(() => {
    setNavigationActivated(true);
  }, []);


  useEffect(() => {
    setInvitationLink(`/skapa-webbshop/${teamData?.team?.associationSlug}/${teamData?.team?.slug}/${teamData?.team?.decryptedTeamMemberCreationToken}`);
  }, [associationData, teamData]);

  const copyText = (e) => {
    const text = document.querySelector('.teammembers-invite').innerText

    const textarea = document.createElement('textarea');
    textarea.value = text;
    document.body.appendChild(textarea);

    textarea.select()
    document.execCommand('copy');
    document.body.removeChild(textarea);

    // e.target.textContent = 'Kopierat!'
    setIsTextCopied(true);
    setIsLinkCopied(false);
  };

  return (
    <div id="sa-enkelt-home" className={`team`} >
      <PageHeader>
        <h1>{teamData && teamData.team.name}</h1>
        <p>{associationData && associationData.association.name}</p>
      </PageHeader>
      <div className='webshop'>
        <Onboarding team={teamData} />
        <HowTo team={teamData} />
      </div>
      <div className='personal-webshop'>
        <div className="container">
          <div className="box md">
            <img className="shop-img" src={PersonalWebshops} alt="" />
            <h3>Sälj mer med personliga webbshoppar!</h3>
            <p>Låt dina säljare skapa personliga shoppar. Ni som grupp får total kontroll och kan se exakt hur många rabatthäften varje person har sålt.</p>
            <div className="box md relative">
              <div className='title'><h4>Hur gör du?</h4></div>
              <ol className='flex flex-col gap-1 p-0'>
                <div className='flex gap-1'>
                  <img src={One} alt="1" />
                  Skicka ut länken nedan till säljarna
                </div>
                <div className='flex gap-1'>
                  <img src={Two} alt="" />
                  Låt säljarna skapar sina shoppar via länken
                </div>
                <div className='flex gap-1'>
                  <img src={Three} alt="" />
                  Klart! Nu kan alla som vill sälja via sin shop!
                </div>
              </ol>
            </div>
            <p className='bold text-left small'>Textförslag samt länk att dela:</p>
            <div className={`box md flex flex-col ${isTextCopied || isLinkCopied ? 'copied' : ''}`}>
              <div className='teammembers-invite'>
                <div>
                  <p>Hej!<br /> Vi ska börja sälja Så enkelt, ett aktivitetshäfte med rabatter i mobilen - för hela familjen.</p>
                  <p>För att nå bästa försäljning skulle vi önska att alla i laget skapade sin egen personliga webbshop att sälja genom.</p>
                  <p>Du gör det enkelt genom att klicka på länken nedan och följa instruktionerna.<br />Det tar typ max 4 min!</p>
                  <p><small className='bold'>Följ länken för att skapa en personlig shop:<br />
                  </small>
                  <small className='underline'>{REACT_APP_BASE_URL}{invitationLink}</small>
                  </p>
                </div>
              </div>
              <Button className='primary lg' onClick={(e) => copyText(e)}>
                {isTextCopied && !isLinkCopied ? 'Texten har kopierats!' : 'Kopiera text + länk'}
              </Button>
              <Button className='secondary lg' onClick={(e) => {
                navigator.clipboard.writeText(`${REACT_APP_BASE_URL}${invitationLink}`);
                setIsLinkCopied(true);
                setIsTextCopied(false);
              }}>
                {isLinkCopied && !isTextCopied ? 'Länken har kopierats!' : 'Kopiera länken'}
              </Button>
            </div>
          </div>
        </div>
        <div className='guide-container'>
          <div className='guide md box container flex flex-col'>
            <div className='box md flex flex-col'>
              <h5 className='bold'>Här hittar du en guide över hur man skapar en personlig webbshop</h5>
              <button className='bold'><a target="_blank" rel="noopener noreferrer" href="https://saenkelt.se/guide-personlig-webbshop/">Öppna guiden &#187;</a></button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}


