import React from 'react';
import { useNavigationContext } from '../../context/NavigationContext';
import TextCollapse from '../../common/TextCollapse';
import Box from '../../common/Box';
import { Button } from '../../common/index';
import exclamation from '../../img/help-exclamation.svg'
import question from '../../img/help-question.svg'
import support from '../../img/help-support.svg'
import play from '../../img/play.svg'
import powerpoint from '../../img/powerpoint.svg'
import Support from './Support';
import SlideImg from '../../img/saljare-slide.svg';
import { useQuery } from '@apollo/client';
import { getTeammemberById } from '../../queries/teamMember';

const { REACT_APP_BRAND } = process.env;
function DashboardHelp(props) {
  const { teammember: teammemberId } = props.match.params;

  const [currentView, setCurrentView] = React.useState('start');
  const possession = JSON.parse(localStorage.getItem('POSSESSION'));

  const { setNavigationActivated } = useNavigationContext();
  React.useEffect(() => {
    setNavigationActivated(true);
  }, []);

  const { data: teamMemberData } = useQuery(getTeammemberById, { variables: { teamMemberId: teammemberId } });
  console.log(teamMemberData);

  return (
    <div id="helpview">
      {/* <h2>{currentView === 'sale' ? 'Säljtips' : currentView === 'faq' ? 'Frågor och svar' : currentView === 'support' ? 'Support' : 'Hjälp'}</h2> */}
      <div className="container">

        <div>
          <img width={300} src={SlideImg} alt="" className="img-slide"></img>
          <p>Sidan är under utveckling och kommer att utformas i efterhand med hjälp av era frågor och tips. Vi tar tacksamt emot dessa på: <b>
            {REACT_APP_BRAND === 'SAENKELT'
              ? ' info@saenkelt.se'
              : ' info@cityhaftet.se'
            }
          </b>
          </p>
        </div>



        {currentView !== 'start' && <Button className='sm secondary' onClick={() => setCurrentView('start')}>‹ Tillbaka</Button>}

        {currentView === 'sale' ?
          <div className='sale'>
            <div>
              <h3>Powerpoint</h3>
              <div className="box powerpoint">
                <div>
                  <img src={powerpoint} alt="" />
                  <p>Öppna powerpoint</p>
                </div>
              </div>
            </div>

            <div>
              <h3>Såhär gjorde Lobas Basket</h3>
              <div className="box video-player">
                <div>
                  <img src={play} alt="" />
                  <p>Se filmen</p>
                </div>
              </div>
            </div>
          </div>

          : currentView === 'faq' ?
            <div>
              <TextCollapse
                title={'Hur kommer vi igång med försäljningen?'}
                // eslint-disable-next-line max-len
                text={'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.'}
              />

              <TextCollapse
                title={'Vad behöver jag som lagledare göra?'}
                // eslint-disable-next-line max-len
                text={'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.'}
              />

              <TextCollapse
                title={'Hur kommer lagmedlemmen igång?'}
                // eslint-disable-next-line max-len
                text={'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.'}
              />

              <TextCollapse
                title={'Hur köper man häftet?'}
                // eslint-disable-next-line max-len
                text={'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.'}
              />
            </div>

            : currentView === 'support' ?
              <Support type={possession?.type} />

              : <div className='start'>
                <div >
                  {/*PUT BACK IN DIV ABOVE: onClick={() => setCurrentView('sale')} */}
                  <Box>
                    <div>
                      <h3>Säljtips &#8250;</h3>
                      <p>Kommer snart</p>
                    </div>
                    <img src={exclamation} alt="" />
                  </Box>
                </div>
                <div >
                  {/*PUT BACK IN DIV ABOVE: onClick={() => setCurrentView('faq')} */}
                  <Box>
                    <div>
                      <h3>Frågor och svar &#8250;</h3>
                      <p>Kommer snart</p>
                    </div>
                    <img src={question} alt="" />
                  </Box>
                </div>
                <div >
                  {/*PUT BACK IN DIV ABOVE: conClik={() => setCurrentView('support')}  */}
                  <Box>
                    <div>
                      <h3>Support &#8250;</h3>
                      <p>Kommer snart</p>
                    </div>
                    <img src={support} alt="" />
                  </Box>
                </div>
              </div>
        }
      </div>
    </div>
  )
}

export default DashboardHelp;
