import React, { useEffect, useRef, useState } from 'react'
import { useLazyQuery, useQuery } from '@apollo/client'
import { getBuyableBookletsQuery, getBookletQuery, getMyUserQuery } from '../../queries/queries'
import { useNavigationContext } from '../../context/NavigationContext'
import { Link, useHistory, useLocation } from 'react-router-dom';
import BookletCard from './BookletCard'

export default function BookletListView({ match }) {
  const { pathname } = useLocation();
  const { data: buyableBookletsData } = useQuery(getBuyableBookletsQuery);
  const [getBooklet, { data: bookletData }] = useLazyQuery(getBookletQuery);
  const { data: userData, loading: userLoading } = useQuery(getMyUserQuery);
  const history = useHistory();

  const bookletsRef = useRef();
  const [selectedBooklet, setSelectedBooklet] = useState(null);
  const isPappersHafte = pathname.includes('pappershaften');
  const currentPath = pathname.endsWith('/') ? pathname : pathname + '/';

  const { setNavigationActivated } = useNavigationContext();
  useEffect(() => {
    setNavigationActivated(false);
  }, []);

  useEffect(() => {
    if (match.params.booklet) {
      const selected = buyableBookletsData?.booklets.find(booklet => booklet._id === match.params.booklet);
      if (selected) {
        setSelectedBooklet({ ...selectedBooklet, value: selected._id, label: selected.title })
      }
    }
  }, [match.params.booklet])

  useEffect(() => {
    if (selectedBooklet?.value) {
      getBooklet({ variables: { _id: selectedBooklet.value } })
    }
  }, [selectedBooklet])


  if (!userLoading && isPappersHafte && !userData?.user.superAdmin) {
    alert('Du har inte behörighet att se denna sida');
    history.push('/');
  }

  return (
    <div className={`booklets saenkelt ${selectedBooklet ? 'bg-white' : ''}`} ref={bookletsRef}>
      <div className='booklets-header' style={{ backgroundColor: bookletData?.booklet.colorLeft }}>
        <h1>{isPappersHafte ? 'Pappershäften' : 'Våra häften'}</h1>
        <div className="container">
          {buyableBookletsData?.booklets.map(booklet => (
            <Link key={booklet._id} to={currentPath + booklet._id} className='box'>
              <BookletCard booklet={booklet} />
            </Link>
          ))}
        </div>
      </div>
    </div>
  )
}

