import React, { useState } from 'react';
import { addTeamMutation } from '../../../queries/queries';
import { useMutation } from '@apollo/client';
import { inviteTeamAdmin } from '../../../queries/invitations';
import { Button } from '../../../common/index';
import { inputValidator } from '../../../common/Helpers';
import close from '../../../img/deelo_close.svg';
import removeCircle from '../../../img/remove-circle-icon.svg'
import { useAutosize } from '../../../common/Helpers';

function ModalAddTeam(props) {
  const { associationId, isFirstTeam, setIsFirstTeam, setEditing, refetchTeam } = props;

  const { height } = useAutosize();
  const [overflow, setOverflow] = useState(false);
  const boxRef = React.useRef();

  const [team, setTeam] = React.useState({
    name: '',
    adminEmails: ['']
  });

  const [step, setStep] = useState(0);
  const [errorVisible, setErrorVisible] = useState(false);
  const [isValidForm, setIsValidForm] = useState(false);
  const [validation, setValidation] = useState({
    ...team,
    name: inputValidator('default', ''),
    // adminEmails: inputValidator('emails', [''])
  });
  const [leader, setLeader] = useState(false);

  const [invitationTeamAdmin] = useMutation(inviteTeamAdmin, {
    onCompleted: (res) => {
      console.log('Inbjudan skickad till lagledare', res);
      setStep(2);
      setIsFirstTeam(false);
      // refetchTeam();
    },
    onError: (err) => console.log(err)
  });

  const [addTeam] = useMutation(addTeamMutation, {
    variables: {
      associationId: associationId,
      name: team.name,
      // adminEmails: team.adminEmails
    },
    onCompleted: (result) => {
      if (!leader) {
        console.log('ingen ledare vald')
        setStep(2)
        setIsFirstTeam(false);

      } /*else {
        team.adminEmails.forEach(adminEmail => {
          invitationTeamAdmin({
            variables: {
              email: adminEmail,
              teamId: result.createTeam._id,
              portalUrlForEmails: `${REACT_APP_BASE_URL}/`
            }
          })
        })
      }*/


    }
  });


  React.useEffect(() => {
    if (validation) {
      const validationFields = Object.values(validation);
      const isValid = validationFields.flat(1).every(field => field.valid === true);
      console.log(validationFields)
      setIsValidForm(isValid);
    }
  }, [team, validation])

  const submitTeam = (e) => {
    e.preventDefault();

    if (!isValidForm && leader) {
      setErrorVisible(true);
      console.log('Ogiltiga fält')
      return null;
    }
    if (isFirstTeam) {
      setStep(2);
    } else {
      addTeam();
    }
  };

  React.useEffect(() => {
    if (boxRef?.current?.clientHeight / height >= 0.9) {
      setOverflow(true);
      document.querySelector('body').style.cssText = 'overflow: hidden;';
    } else {
      setOverflow(false);
      document.querySelector('body').style.cssText = '';
    }
  }, [height, team?.adminEmails?.length]);


  return (
    <div className={`open modal-form modal ${overflow ? 'autosize' : ''}`}>
      <div ref={boxRef} className={`box ${overflow ? 'autosize' : ''}`}>
        <div className="close" onClick={() => setEditing('')}>
          <img src={close} alt="" />
        </div>
        {/* Step 1 - First team? */}
        {step === 0 && isFirstTeam ? <>
          <h3 className='mb-lg'>Vi ser att det är första gången du lägger till ett lag</h3>
          <p>Om du är osäker på hur det fungerar när du lägger till ett lag kan du kolla videon på FAQ-sidan</p>
          <Button className='primary lg arrow' onClick={() => setStep(step + 1)}>Lägg till ett lag</Button>
          <Button className="bg-none" onClick={() => setEditing('')}>Avbryt</Button>
        </>

          // Else show form
          : (step === 0 || step === 1) && <>
            <h3 className='mb-lg'>Lägg till lag</h3>
            <form noValidate onSubmit={submitTeam}>
              {/* Team name */}
              <div className={`form-row`}>
                <label htmlFor={'name'}>Lagnamn</label>
                <input type="text" name='name' onChange={(e) => {
                  setValidation({ ...validation, name: inputValidator('name', e.target.value) });
                  setTeam({ ...team, name: e.target.value });
                }} autoComplete="off" />
                {(!validation.name?.valid && errorVisible) && <small className="error">{validation.name?.error}</small>}
              </div>
              {/* Admin emails */}
              {!leader ?
                <button className='bg-none m-0 email-increment btn-invite-team-admins' onClick={() => { setLeader(true) }}>Bjud in lagledare</button>
                :
                <>
                  {team.adminEmails.map((admin, index) => (
                    <div className={`form-row relative`} key={index}>
                      <label htmlFor={'email'}>Lagledarens e-post</label>
                      {index >= 0 && <Button onClick={() => {
                        // Decrement admin email
                        const inputDecrement = [...team.adminEmails];

                        {
                          index === 0 ? setLeader(false)
                            : inputDecrement.splice(index, 1);
                          setTeam({ ...team, adminEmails: inputDecrement })
                          setValidation({ ...validation, adminEmails: inputValidator('emails', inputDecrement) })
                        }
                      }} className='bg-none m-0 email-decrement'>
                        <img src={removeCircle} alt="" />
                      </Button>}
                      <input type="email" disabled placeholder='Funktion avstängd' name='email' onChange={(e) => {
                        const updateAdminEmail = [...team.adminEmails];
                        updateAdminEmail[index] = e.target.value;
                        setValidation({ ...validation, adminEmails: inputValidator('emails', updateAdminEmail) });
                        setTeam({ ...team, adminEmails: updateAdminEmail });
                      }} autoComplete="off" />
                      {(!validation.adminEmails[index]?.valid && errorVisible) && <small className="error">{validation.adminEmails[index]?.error}</small>}
                    </div>
                  ))}

                  {/* Increment admin email */}
                  <Button onClick={() => {
                    setTeam({ ...team, adminEmails: [...team.adminEmails, ''] })
                    const inputIncrement = [...team.adminEmails];
                    inputIncrement.push('');
                    setValidation({ ...validation, adminEmails: inputValidator('emails', inputIncrement) })
                  }} className='bg-none m-0 email-increment btn-invite-team-admins'>Lägg till fler lagledare +</Button>
                </>
              }




              <button type="submit" className="lg primary">Lägg till
                {/*leader ? 'Lägg till och bjud in' : 'Lägg till'*/}
                {/*<img src={envelope} alt="" />*/}
              </button>
            </form>
          </>
        }

        {/* Step 2 - First team? */}
        {step === 2 && isFirstTeam
          ? <>
            <h3>Har du gjort utskicket?</h3>
            <p>Innan du lägger till lag behöver du informera alla medlemmar att ni ska börja sälja. Har du gjort det?</p>
            <Button className='secondary lg' onClick={addTeam}>Ja</Button>
            <Button className='secondary lg' onClick={() => {
              setStep(0);
              setEditing('');
            }}>Nej</Button>
          </>

          // Else finished!
          : leader && step === 2 ? <>
            <h3>Laget är tillagt!</h3>
            <p>{team.name}</p>
            {team.adminEmails.map((email, index) => (
              <p key={index} className='m-0'>{email}</p>
            ))}
            <p className='m-0'>har fått en inbjudan på sin mail.</p>
            <Button className='secondary lg' onClick={() => setStep(0)}>Lägg till ett lag till +</Button>
            <Button className='primary lg' onClick={() => {
              setStep(0);
              setEditing('');
              refetchTeam();
            }}>Jag är klar</Button>
          </>
            : !leader && step === 2 && <>
              <h3>Laget är tillagt!</h3>
              <p className='mb-lg'>{team.name}</p>

              <Button className='secondary lg' onClick={() => setStep(0)}>Lägg till ett lag till +</Button>
              <Button className='primary lg' onClick={() => {
                setStep(0);
                setEditing('');
                refetchTeam();
              }}>Jag är klar</Button>
            </>

        }
      </div>
    </div>
  );
}

export default ModalAddTeam;
