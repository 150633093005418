import React, { useState, useEffect } from 'react';
import { useLazyQuery, useQuery } from '@apollo/client';
import { orderByCodeQuery, getBookletQuery } from '../../queries/queries';
import Apple from '../../img/appstore_sv.png'
import Google from '../../img/googleplay_sv.png'
import { IoMdCopy } from 'react-icons/io';
import { Button } from '../../common';
import { useHistory } from 'react-router';
import close from '../../img/deelo_close.svg';
import { useNavigationContext } from '../../context/NavigationContext';
import WebshopNavigation from './WebshopNavigation';
import { FaChevronRight } from 'react-icons/fa';
import BgShapes from '../../img/saenkelt-bg-shapes.png';

const { REACT_APP_BRAND } = process.env;

function OrderConfirm({ match }) {

  const history = useHistory();

  const [orderStatus, setOrderStatus] = useState('created');
  const [order, setOrder] = useState(null);
  const [isCodeCopied, setIsCodeCopied] = useState(false);
  const [isTextCopied, setIsTextCopied] = useState(false);
  const [openShareModal, setOpenShareModal] = useState(false);

  const [getBooklet, { data: bookletData }] = useLazyQuery(getBookletQuery);
  const { data: orderData } = useQuery(orderByCodeQuery, { variables: { code: order?.code }, pollInterval: 2000 });

  const appStoreLink = REACT_APP_BRAND === 'CITY' ? 'https://apps.apple.com/se/app/cityh%C3%A4ftet/id1577900667' : 'https://apps.apple.com/se/app/s%C3%A5-enkelt/id6444106162';
  const googlePlayLink = REACT_APP_BRAND === 'CITY' ? 'https://play.google.com/store/apps/details?id=se.cityhaftet.cityhaftet' : 'https://play.google.com/store/apps/details?id=se.saenkelt.app';

  const { setNavigationActivated } = useNavigationContext();
  React.useEffect(() => {
    setNavigationActivated(false);
  }, []);

  useEffect(() => {
    setOrder(JSON.parse(Buffer.from(match?.params?.order, 'base64').toString('utf-8')));
    setOrderStatus(orderData?.orderByCode?.status);
  }, [orderData?.orderByCode?.status]);

  useEffect(() => {
    if (orderData?.orderByCode?.bookletId) {
      getBooklet({ variables: { _id: orderData?.orderByCode?.bookletId } });
    }
  }, [orderData?.orderByCode?.bookletId]);

  const cancelOrder = () => {
    history.push('/');
  };

  const copyCode = () => {
    navigator.clipboard.writeText(order?.code);
    setIsCodeCopied(true);
    setIsTextCopied(false);
  };

  const copyText = (e) => {
    const text = document.querySelector('.share-text').innerText

    const textarea = document.createElement('textarea');
    textarea.value = text;
    document.body.appendChild(textarea);

    textarea.select()
    document.execCommand('copy');
    document.body.removeChild(textarea);

    setIsTextCopied(true);
    setIsCodeCopied(false);
  };

  return (
    <div id="se-order">
      <div className='bg-shapes'>
        {REACT_APP_BRAND === 'SAENKELT' && <img src={BgShapes} alt="" />}
      </div>
      <WebshopNavigation />
      {orderStatus === 'completed' ?
        <div className="order-completed flex">
          <div className="container flex items-center justify-center flex-col">
            <h1 className='mb'>Tack för ditt köp</h1>
            <h5>Hälsar {order?.teamMemberFirstname} {order?.teamMemberLastname}</h5>
            {order?.associationName && <h5>{order?.teamName && `${order?.teamName}, `}{order?.associationName}</h5>}
            <div className="box md my-lg">
              {REACT_APP_BRAND === 'CITY'
                ? <h2 className='mb'>Ange koden i Cityhäftet-appen</h2>
                : <h2 className='mb'>Ange koden i Så enkelt-appen</h2>
              }
              <p className='mb-lg'>Det är viktigt att du sparar denna kod, ta gärna en skärmdump!
                Koden gäller för {bookletData?.booklet?.title} som är giltigt t.o.m. {bookletData?.booklet?.validTo.split('T')[0]}.</p>
              <div onClick={copyCode} className={`code my c-pointer ${isCodeCopied ? 'is-copied' : ''}`}>
                <span>{order?.code}</span>
                <div className={`copy-icon c-pointer flex flex-col items-center justify-center ${isCodeCopied ? 'is-copied' : ''}`}>
                  <IoMdCopy size={32} />
                  {/* <small>{isCodeCopied ? 'Kopierad!' : 'Kopiera'}</small> */}
                </div>
              </div>
              <div className="download-buttons my-lg">
                <Button className='btn-app' onClick={() => window.location.assign(appStoreLink)}>
                  <img src={Apple} alt="" />
                </Button>
                <Button className='btn-app' onClick={() => window.location.assign(googlePlayLink)}>
                  <img src={Google} alt="" />
                </Button>
              </div>
              <div className="code-payment c-pointer flex items-center" onClick={() => setOpenShareModal(true)}>
                Jag har köpt koden åt någon annan <FaChevronRight size={12} />
              </div>
            </div>
          </div>
        </div>

        : <div className='se-swish flex items-center justify-center'>
          {orderStatus === 'pending' && <>
            <h2>Betalning</h2>
            <div className="box flex items-center justify-center">
              <div>
                <div className="pay-methods" />
                <p>Starta swish på den enhet du önskar genomföra betalningen.</p>
              </div>
            </div>
            <div className='cancel' onClick={cancelOrder}>Avbryt ›</div>
          </>
          }
          {orderStatus === 'aborted' && <>
            <h2>Webbshop</h2>
            <div className="box flex items-center justify-center">
              <div>
                <div className="pay-methods" />
                <p>Betalning avbruten.</p>
              </div>
            </div>
            <div className='cancel' onClick={cancelOrder}>Börja om beställningen ›</div>
          </>
          }
        </div>
      }
      {openShareModal &&
        <div className="share-code-modal modal">
          <div className="box md">
            <button className="close x" onClick={() => setOpenShareModal(false)}><img src={close} alt="" /></button>
            <div>
              <h1>Skicka koden</h1>
              <p>Skicka appkoden till den som ska ha häftet.<br />Här är ett förslag på vad du kan skriva:</p>
              <div className={`box md ${isTextCopied || isCodeCopied ? 'copied' : ''}`}>
                <div className="share-text">
                  <p>Hej!</p>
                  <p>Här kommer din appkod för {bookletData?.booklet?.title}<br />Ladda ner appen {REACT_APP_BRAND === 'CITY' ? 'Cityhäftet' : 'Så enkelt'} och ange koden.</p>
                  <p>Här är din kod:<br />{order?.code}</p>
                </div>
                <Button onClick={copyText} className="primary lg">
                  {isTextCopied && !isCodeCopied ? 'Texten har kopierats!' : 'Kopiera texten'}
                </Button>
                <Button className='secondary lg' onClick={copyCode}>
                  {isCodeCopied && !isTextCopied ? 'Länken har kopierats!' : 'Kopiera länken'}
                </Button>
              </div>
            </div>
          </div>
        </div>
      }
    </div>
  );
}

export default OrderConfirm;
