import React, { useEffect, useRef } from 'react'
import PageWrapper from './PageWrapper';
import { usePappersHafteContext } from '../../context/PappersHafteContext';
import Barcode from 'react-barcode';

const { REACT_APP_IMAGE_BASE_URL } = process.env;

export default function PappersHafteCompanyCoupon({ booklet, company, coupon }) {
  const { allPages } = usePappersHafteContext();
  const page = allPages.findIndex(pageCoupon => pageCoupon?._id === coupon._id) + 1;

  const categoryColor = company?.category?.color || booklet?.colorLeft;

  const validFrom = new Date(booklet?.validFrom).toLocaleDateString('sv-SE').replace(/-/g, '').slice(2);
  const validTo = new Date(booklet?.validTo).toLocaleDateString('sv-SE').replace(/-/g, '').slice(2);
  const validPeriod = `${validFrom} – ${validTo}`;

  const formattedURL = company?.websiteURL ? company.websiteURL.replace(/^https?:\/\/(www\.)?/, '').replace(/\/$/, '') : '';

  const containerRef = useRef(null);

  // Hide comma after phone when url has wrapped to next line
  useEffect(() => {
    if (containerRef.current) {
      const phone = containerRef.current.children[0];
      const comma = containerRef.current.children[1];
      const url = containerRef.current.children[2];

      if (phone && comma && url && url.offsetTop > phone.offsetTop) {
        comma.style.display = 'none';
      } else if (phone && comma && url) {
        comma.style.display = 'inline';
      }
    }
  }, [company?.phone, formattedURL]);

  return (
    <PageWrapper className='ph-company-coupon'>

      <div className="ph-coupon-stripes" />

      <div className='ph-company-coupon-content relative'>
        <div className='ph-company-coupon-logo-container'>
          <img className="ph-company-coupon-logo" src={REACT_APP_IMAGE_BASE_URL + company.logoURL} alt='logo' />
        </div>
        {/* <img className="ph-company-coupon-logo" src={REACT_APP_IMAGE_BASE_URL + company.logoURL} alt='logo' /> */}
        <h1>{coupon.discount}</h1>
        <div className="discount flex flex-col">
          <div className='flex flex-col'>
            <div className='discount-info'>
              <p className="smallTitle">
                {coupon?.smallTitle}
              </p>
              <p>{coupon?.description}</p>
            </div>
            <div className='contact-info'>
              <p>{company?.name}</p>
              <p>{company?.visitAddress?.streetAddress}, {company?.visitAddress?.city}</p>
              <div className='phone-url' ref={containerRef}>
                <span>{company?.phone}</span>
                {company?.phone && formattedURL && <span className="comma">,{'\u00A0'}</span>}
                <span style={coupon?.code ? {} : { whiteSpace: 'nowrap' }}>{formattedURL}</span>
              </div>
            </div>
          </div>
          {coupon?.code &&
            <div className='ph-barcode-container'>
              <Barcode
                value={coupon?.code}
                width={0.9}
                height={26}
                fontSize={9}
                background='transparent'
              />
            </div>
          }
          <div className='flex bottom-discount-info'>
            <p>{coupon?.terms}</p>
            <div className='bottom-discount-validto'>
              <p>Giltig</p>
              <p style={{ whiteSpace: 'nowrap' }}>{validPeriod}</p>
            </div>
          </div>
        </div>
      </div>
      <div className="coupon-category-group" style={{ background: categoryColor }}>
        <span>{page}</span>
        <p>{coupon.company?.category?.name}</p>
      </div>
    </PageWrapper>
  )
}

