import React from 'react';
import PropTypes from 'prop-types';


function sortList(arr, sort) {
  const { sortBy, sortOrder } = sort;
  return [].concat(arr).sort((a, b) => (
    (typeof a[sortBy] === "string" ? a[sortBy].toLowerCase() : (typeof a[sortBy] === "number" ? a[sortBy] : -1)) >
    (typeof b[sortBy] === "string" ? b[sortBy].toLowerCase() : (typeof b[sortBy] === "number" ? b[sortBy] : -1))
      ? sortOrder : sortOrder * -1));
}

sortList.propTypes = {
  obj: PropTypes.array.isRequired,
  sort: PropTypes.object.isRequired,
};

function setSortProp(name, sort){
  console.log(name, sort)
  sort.setSortBy(name)
  sort.setSortOrder(sort.sortOrder)
}

function SortButton(props) {
  const { sort, children, name } = props;
  
  //If name is same. Change order direction
  if (name == sort.sortBy){
    sort.sortOrder = (sort.sortOrder == 1) ? -1 : 1;
    //If order is a-z the button will flip 180 degres and vice verca backwards
    // if(sort.sortOrder == -1){
    //   return "hej"
    // }if(sort.sortOrder == 1){
    //   return "Då"
    // }
  }

  return (<button className="BtnSort" type="button" onClick={() => setSortProp(name, sort)}>{children}</button>);
}

SortButton.propTypes = {
  children: PropTypes.any.isRequired,
  sort: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
};

function EditButton(props) {
  const { size, setEditing, children } = props;
  return (<button className={`primary ${size}`} type="button" onClick={setEditing}>{children}</button>);
}

EditButton.propTypes = {
  size: PropTypes.string,
  children: PropTypes.any.isRequired,
  setEditing: PropTypes.func.isRequired,
};

EditButton.defaultProps = {
  size: 'md',
};

function EmptyList(props) {
  const { empty } = props;
  if (empty) {
    return (<tr><td colSpan="100" className="table-empty">Här var det visst tomt!</td></tr>);
  }
}

EmptyList.propTypes = {
  empty: PropTypes.bool.isRequired,
};


function LoadingTable(props) {
  const { loading, error } = props;
  if (loading) {
    return <tr><td colSpan="100" className="table-loading">Laddar...</td></tr>;
  } if (error) {
    return <tr><td colSpan="100" className="table-error">Upps! Något gick fel...</td></tr>;
  }

  return false;
}

LoadingTable.propTypes = {
  // query: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  error: PropTypes.bool.isRequired,
};

function TableSection(props) {
  const { children } = props;
  return (
    <div className="table">
      <div className="container">
        {children}
      </div>
    </div>
  );
}

TableSection.propTypes = {
  children: PropTypes.array.isRequired,
};

export { sortList, SortButton, EditButton, LoadingTable, EmptyList, TableSection };
