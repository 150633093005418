import React from 'react'
import { Button } from '../../common';
import { FiCheck } from 'react-icons/fi';

const { REACT_APP_IMAGE_BASE_URL } = process.env;

function BookletCard({ booklet, setBookletModal, handleSelectedBooklet, selectedBooklet }) {
  return (
    <div key={booklet._id} className="box">
      <div className="top">
        <div className="bg left" style={{ backgroundColor: booklet.colorLeft }}></div>
        <div className="bg right" style={{ backgroundColor: booklet.colorRight }}></div>
        <div className="img-container">
          {booklet?.logoURL && <img src={REACT_APP_IMAGE_BASE_URL + booklet.logoURL} alt="" />}
        </div>
      </div>

      <div className={`bottom-row ${selectedBooklet?._id === booklet._id ? 'selected' : ''}`}>
        <div>
          <div><b>{booklet?.title}</b></div>
          <div><b>{booklet.price ? booklet.price : 0} kr</b></div>
          <small>Häfte aktiveras i iOS/Android</small>
          {(booklet?.validFrom && booklet?.validTo) && <small>Giltig {new Date(booklet.validFrom).toLocaleDateString().replaceAll('/', '-')} - {new Date(booklet.validTo).toLocaleDateString().replaceAll('/', '-')}</small>}
        </div>
        <div className="button-group">
          <Button onClick={() => setBookletModal({ open: true, booklet: booklet })} className="sm">
            Visa innehåll
          </Button>
          <Button onClick={() => handleSelectedBooklet(booklet)} className="sm">
            {selectedBooklet?._id === booklet._id ? (
              <>
                <FiCheck />
                Tillagd
              </>
            ) : 'Lägg i varukorg'}
          </Button>
        </div>
      </div>
    </div>
  )
}

export default BookletCard
