import React, { useState, useEffect } from 'react'
import { Button } from '../../common';
import ImgHome from '../../img/sa-enkelt-red-home.svg';
import ImgStats from '../../img/sa-enkelt-red-statistik.svg';
import ImgWebbshop from '../../img/sa-enkelt-red-webbshop.svg';
import ImgWebshopTeam from '../../img/shop_users_outline.svg';
import { useQuery, useMutation } from '@apollo/client';
import { getMyUserQuery, updateMyUserMutation } from '../../queries/queries';


function Onboarding({ seller, team }) {

  const [showModal, setShowModal] = useState(false);
  const [closeModal, setCloseModal] = useState(false);
  const { data, refetch } = useQuery(getMyUserQuery);
  const [updateTutorialVersion] = useMutation(updateMyUserMutation)


  useEffect(() => {
    if (seller && data.user.teamMemberTutorialVersion !== 2) {
      setShowModal(true)
    } else if (team && data.user.teamTutorialVersion !== 2) {
      setShowModal(true)
    } else setShowModal(false)

  }, [seller, team]);


  const handleCloseModal = () => {
    if (seller) {
      console.log(seller)
      updateTutorialVersion({ variables: { teamMemberTutorialVersion: 2 }, refetchQueries: refetch });
      setCloseModal(true);
      setTimeout(() => setShowModal(false), 250)

    } if (team) {
      console.log(team)
      updateTutorialVersion({ variables: { teamTutorialVersion: 2 }, refetchQueries: refetch });
      setCloseModal(true);
      setTimeout(() => setShowModal(false), 250)
    }
  }


  return (
    <div className={`modal modal-onboarding onboarding-modal ${showModal ? 'open' : ''} ${closeModal ? 'closing' : ''}`}>
      <div className="modal-content box fixed-bottom">
        <div>
          <h3>Välkommen!<br />På Mina sidor hittar du:</h3>
          <ul>
            <li>
              <img src={ImgHome} alt="" />
              {seller ?
                <div><h5>Hur du säljer häften</h5></div>
                :
                <div><h5>Hur ni säljer häften</h5></div>
              }
            </li>
            <li>
              <img src={ImgStats} alt="" />
              {seller ?
                <div><h5>Hur många häften du sålt</h5></div>
                :
                <div><h5>Statistik över försäljningen</h5></div>
              }
            </li>
            <li>
              <img src={seller ? ImgWebbshop : ImgWebshopTeam} alt="" />
              {seller ?
                <div><h5>Direktlänk till din shop</h5></div>
                :
                <div><h5>Personliga webshoppar</h5></div>
              }


            </li>
          </ul>
        </div>
        <Button onClick={handleCloseModal} className={'lg primary alt'}>Gå vidare</Button>
      </div>
    </div>
  )
}

export default Onboarding
