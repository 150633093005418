import React from 'react'
import { motion } from 'framer-motion'

const { REACT_APP_BRAND } = process.env;

export default function SlideContent(props) {
  const { children, initial } = props;
  return (
    <div className="slide">
      <div className="top-bg">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 375 33.832" className={REACT_APP_BRAND === 'CITY' ? 'cityhaftet' : ''}>
          <path
            d="M0-6.015c141.406-25.809,212.874,27.269,375-.069v26.6H0S0,20.758,0-6.015Z"
            transform="translate(0 13.314)"
          />
        </svg>
      </div>
      <div className="bottom-bg"></div>
      <motion.div
        initial={{ opacity: initial }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.7 }}
        className="guide-content">
        {children}
      </motion.div>
    </div>

  )
}

//Skapar den gröna vågiga bakgrunden och children är all content på slide:n.
