import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import PropTypes from 'prop-types';
import { getAssociationsInZoneQuery } from '../../queries/queries';
import { LoadingTable, EditButton, sortList, TableSection } from '../../common/index';
import Form from './AssociationsForm';
import Association from './Association';

function AssociationsTable(props) {
  const { zoneId } = props;
  const [editing, setEditing] = useState('');
  const [sortBy, setSortBy] = useState('name');
  const [sortOrder, setSortOrder] = useState(1);
  const sort = { sortBy, sortOrder, setSortBy, setSortOrder };
  const [statDates, setStatDates] = useState({ fromDate: null, toDate: null });

  const { data: associationsQuery, loading, error } = useQuery(getAssociationsInZoneQuery, {
    variables: { zoneId: zoneId }
  });

  const handleChangeOnInput = (e) => {
    let date = e.target.value;
    let name = e.target.name;

    if (name === "fromDate") {
      setStatDates({ ...statDates, fromDate: date });
    } else {
      setStatDates({ ...statDates, toDate: date });
    }
  }

  return (
    <TableSection>
      <div className="table-header">
        <h2>Föreningar</h2>
        <EditButton size="sm add" setEditing={() => setEditing('new')}>Lägg till</EditButton>
      </div>
      <div className="stat-dates">
        <form className="stat-form stat-form-associations">
          <p>Välj datum för att se statistik för specifikt tidsintervall</p>
          <div>
            <label htmlFor="fromDate">Från</label>
            <input type="date" id="fromDate" name="fromDate" onChange={handleChangeOnInput} />
          </div>
          <div>
            <label htmlFor="toDate">Till</label>
            <input type="date" id="toDate" name="toDate" min={statDates.fromDate && statDates.fromDate} onChange={handleChangeOnInput} />
          </div>
        </form>
      </div>

      <table className='associations-table'>
        <thead>

          <tr>
            <td>Förening</td>
            <td>Administratörer</td>
            <td>Anteckningar</td>
            <td></td>
            <td>Aktiverade/Sålda</td>
          </tr>

        </thead>
        <tbody>

          {editing === 'new'
            && <Form key="new" content={{ zoneId }} onComplete={() => setEditing('')} />}

          {!associationsQuery?.associations ? <LoadingTable loading={loading} error={error} /> : (
            sortList(associationsQuery?.associations, sort).map((obj) => (
              editing === obj._id
                ? <Form key={obj._id} content={obj} onComplete={() => setEditing('')} />
                : <Association key={obj._id} association={obj} setEditing={setEditing} statDates={statDates} />
            )))}

        </tbody>
      </table>
    </TableSection>
  );
}

AssociationsTable.propTypes = {
  zoneId: PropTypes.string.isRequired,
};

export default AssociationsTable;
