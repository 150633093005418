import React from 'react'
import InfoShape from './InfoShape'
import { useAutosize } from '../../common/Helpers';
import BookletCompany from '../vara-haften/BookletCompany';
import BookletCompanyReview from './BookletCompanyReview';

const { REACT_APP_IMAGE_BASE_URL } = process.env;

const CompanyReviewBooklet = ({ booklet, companyReviewData, companyTypesData }) => {
  const { width } = useAutosize();
  const isDesktop = width > 992;
  const validTo = new Date(booklet?.validTo).toLocaleDateString('sv-SE', { day: 'numeric', month: 'numeric' }) || '';

  return (
    <>
      <div className="company-review-booklet box flex flex-col items-center">
        {booklet &&
          <div className="company-review-booklet-header relative flex flex-col items-center">
            <div className='booklet-bg left' style={{ backgroundColor: booklet?.colorLeft }} />
            <div className='booklet-bg right' style={{ backgroundColor: booklet?.colorRight }} />
            <img src={REACT_APP_IMAGE_BASE_URL + booklet?.logoURL} alt={booklet?.title} />
            <h3 style={{ color: booklet?.colorFont || '#fff' }}>{booklet?.title}</h3>
            <small style={{ color: booklet?.colorFont || '#fff' }}>Giltigt t.o.m {validTo}</small>
          </div>
        }
        {companyReviewData?.bookletCompanyReviewByToken?.company &&
          <BookletCompanyReview
            companyReview={companyReviewData.bookletCompanyReviewByToken}
          />
        }
        {booklet?.calculatedCompanies?.filter(company => company._id !== companyReviewData.bookletCompanyReviewByToken?.company?._id)
          .map((company, index) => index < 3 && (
            <BookletCompany
              key={company._id}
              company={company}
              companyType={companyTypesData?.types[companyReviewData.bookletCompanyReviewByToken?.company?.typeId]}
              style={{
                filter: 'blur(5px) grayscale(1) opacity(0.5)'
              }}
            />
          ))}
      </div>
      <InfoShape
        title='Såhär ser ert företag ut i häftet'
        color='#fc4b77'
        height={130}
        width={130}
        x={isDesktop ? -105 : '54%'}
        y={isDesktop ? 280 : 180}
        arrowX={isDesktop ? '45%' : '-30%'}
        arrowY={isDesktop ? 105 : '50%'}
        rotate={!isDesktop ? 90 : 0}
      />
    </>
  )
}

export default CompanyReviewBooklet
