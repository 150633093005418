import React from 'react';
import Statistics from './TeamsStatistics';
import Table from './TeamsTable';
import { useQuery } from '@apollo/client';
import { getAssociationPublicQuery, ongoingPeriodsInAssociationQuery } from '../../../queries/queries';
import { useNavigationContext } from '../../../context/NavigationContext';

function DashboardAssociationTeams(props) {

  const { association: associationId } = props.match.params;
  const { data: associationData } = useQuery(getAssociationPublicQuery, { variables: { associationId: associationId } })

  const [period, setPeriod] = React.useState();
  const { data: agreementPeriods } = useQuery(ongoingPeriodsInAssociationQuery, {
    variables: {
      associationId
    }
  });

  const { setNavigationActivated } = useNavigationContext();
  React.useEffect(() => {
    setNavigationActivated(true);
  }, []);

  React.useEffect(() => {
    agreementPeriods && setPeriod(agreementPeriods.periods.currentPeriod)
  }, [agreementPeriods]);

  return (
    <>
      <Table associationId={associationId} associationData={associationData} period={period} />
      <Statistics associationId={associationId} period={period} setPeriod={setPeriod} agreementPeriods={agreementPeriods} />
    </>
  );
}

export default DashboardAssociationTeams;
