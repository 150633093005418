import React from 'react';
import { PageHeader } from '../../common/index';
import Statistics from './CompanyAgreementsStatistics';
import Table from './CompanyAgreementsTable';
import { useNavigationContext } from '../../context/NavigationContext';

const { REACT_APP_BRAND } = process.env;

function CompanyAgreements(props) {
  const { company: companyId, city: cityId } = props.match.params;

  const { setNavigationActivated } = useNavigationContext();
  React.useEffect(() => {
    setNavigationActivated(true);
  }, []);

  return (
    <div id="company-agreements">
      <PageHeader>
        <h4>Välkommen till {REACT_APP_BRAND === 'CITY' ? 'Cityhäftets' : 'Så enkelt'} webbportal, här kan du:</h4>
        <ul>
          <li>Lägga till och hantera era avtalsperioder</li>
          <li>Följa er försäljningsstatistik</li>
        </ul>
        <h6>Support?</h6>
        {REACT_APP_BRAND === 'CITY'
          ? <p><a href="mailto:info@cityhaftet.se">info@cityhaftet.se</a></p>
          : <p><a href="mailto:info@saenkelt.se">info@saenkelt.se</a></p>
        }
          
      </PageHeader>
      <Statistics companyId={companyId} cityId={cityId} />
      <Table companyId={companyId} cityId={cityId} />
    </div>
  );
}

export default CompanyAgreements;
