import gql from 'graphql-tag';

export * from './association';
export * from './booklet';
export * from './city';
export * from './coupon';
export * from './company';
export * from './team';
export * from './teamMember';
export * from './user';
export * from './zone';

// QUERIES

const getPeriodsQuery = gql`
  {
    periods: getPeriods {
      seasonName
      period
      bookletTitle
      startDate
      endDate
    }
  }
`;
const currentPeriodQuery = gql`
  {
    period: currentPeriod {
      period
      startDate
      endDate
      seasonName
      bookletTitle
    }
  }
`

const orderByCodeQuery = gql`
  query orderByCode($code: String!) {
    orderByCode(code: $code) {
      status
      teamMemberId
      teamMemberFirstname
      teamMemberLastname
      associationName
      teamName
      bookletId
    }
  }
`;

const completedOrdersQuery = gql`
  query completedOrdersByBooklet($bookletId: ObjectId!) {
    completedOrdersByBooklet(bookletId: $bookletId) {
      _id
      code
      batchLabel
      bookletId
      status
      userId
      completedDate
      associationName,
      teamName,
      teamMemberFirstname,
      teamMemberLastname,
      swishNumber,
      paidAmount,
      paidDate,
      userFirstname,
      userLastname,
      
    }
  }
`;

// MUTATIONS

const orderMutation = gql`
  mutation($bookletId: ObjectId!, $associationId: ObjectId, $teamId: ObjectId, $teamMemberId: ObjectId) {
    createMyOrder(bookletId: $bookletId, associationId: $associationId, teamId: $teamId, teamMemberId: $teamMemberId) {
      _id
    }
  }
`;

const createOrderMutation = gql`
  mutation($bookletId: ObjectId!, $associationId: ObjectId, $teamId: ObjectId, $teamMemberId: ObjectId) {
    createOrder(bookletId: $bookletId, associationId: $associationId, teamId: $teamId, teamMemberId: $teamMemberId) {
        _id
        code
        bookletId
        bookletTitle
        bookletPrice
        associationId
        associationName
        teamId
        teamName
        teamMemberId
        teamMemberFirstname
        teamMemberLastname
        status
        swishStatus
    }
  }
`;

const createCompletedOrdersMutation = gql`
  mutation(
    $amount: Int!, 
    $bookletId: ObjectId!, 
    $associationId: ObjectId, 
    $teamId: ObjectId, 
    $teamMemberId: ObjectId
    ) {
      createCompletedOrders(
        amount: $amount, 
        bookletId: $bookletId,
        associationId: $associationId,
        teamId: $teamId, 
        teamMemberId: $teamMemberId
      ) {
        _id
        code
      }
    }
`;

const payWithSwishMutation = gql`
  mutation($payerAlias: String!, $orderId: ObjectId!) {
    payWithSwish(payerAlias: $payerAlias, orderId: $orderId)
  }
`;

const payWithSwishMobileMutation = gql`
  mutation($orderId: ObjectId!) {
    payWithSwishMobile(orderId: $orderId)
  }
`;

const orderStatusChangedSubscription = gql`
  subscription($orderId: ObjectId!) {
    orderStatusChanged(orderId: $orderId) {
      orderId
      status
    }
  }
`;

/*
const UPLOAD_FILE = gql`
  mutation($file: FileUpload!) {
    singleUpload(file: $file) {
      filename
      mimetype
      encoding
    }
  }
`;
*/
const uploadMutation = gql`
  mutation($file: File!, $filename: String, $folder: String) {
    singleFileUpload(file: $file, filename: $filename, folder: $folder) {
      partURL
      secureURL
    }
  }
`;

const sendEmail = gql`
  mutation(
    $emails: [GraphQLEmail!]!,
    $title: String!,
    $message: String!
  ) {
    sendEmail(emails: $emails, title: $title, message: $message)
  }
`;

export {
  getPeriodsQuery,
  currentPeriodQuery,
  orderByCodeQuery,
  orderMutation,
  createOrderMutation,
  payWithSwishMutation,
  payWithSwishMobileMutation,
  orderStatusChangedSubscription,
  uploadMutation,
  completedOrdersQuery,
  createCompletedOrdersMutation,
  sendEmail,
};
