import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { getCompanyQuery } from '../../queries/queries';
import { LoadingTable, EditButton, sortList, PeriodById, SeasonById, TableSection, useRight } from '../../common/index';
import Form from './CompanyAgreementsForm';

function CouponLinks(props) {
  const { companyId, agreementId, from, to } = props;
  const children = [];
  for (let i = from; i <= to; i += 1) {
    children.push(
      <Link key={i} className="secondary xs arrow " to={`/cities/companies/${companyId}/agreements/${agreementId}/coupons/${i}`}>
        <SeasonById id={i} />
      </Link>,
    );
  }
  return (children);
}

function CompanyAgreementsTable(props) {
  const { cityId, companyId } = props;
  const [editing, setEditing] = useState('');
  const [sortBy, setSortBy] = useState('activeFrom');
  const [sortOrder, setSortOrder] = useState(1);
  const sort = { sortBy, sortOrder, setSortBy, setSortOrder };

  const { data: companyQuery, loading, error } = useQuery(getCompanyQuery, { variables: { id: companyId } });

  const editRights = useRight({ type: 'city', id: cityId });

  return (
    <TableSection>
      <div className="table-header">
        <h2>Perioder</h2>
        {editRights && <EditButton size="sm add" setEditing={() => setEditing('new')}>Lägg till</EditButton>}
      </div>
      <table>
        <thead>

          <tr>
            <td>Period</td>
          </tr>

        </thead>
        <tbody>

          {editing === 'new'
            && <Form key="new" content={{ companyId }} onComplete={() => setEditing('')} />}

          {!companyQuery?.company ? <LoadingTable loading={loading} error={error} /> : (
            sortList(companyQuery?.company.agreements, sort).map((obj) => (
              editing === obj._id
                ? <Form key={obj._id} content={{ ...obj, companyId }} onComplete={() => setEditing('')} />
                : (
                  <tr key={obj._id}>
                    <td>
                      <PeriodById id={obj.activeFrom} />
                      <span> - </span>
                      <PeriodById id={obj.activeTo} />
                    </td>
                    <td>
                      {editRights && <EditButton size="xs" setEditing={() => setEditing(obj._id)}>Redigera</EditButton>}
                      {editRights && <Link className="secondary xs arrow" type="button" to={`/cities/companies/${companyId}/agreements/${obj._id}/coupon`}>Avtalskupong</Link>}
                      <CouponLinks companyId={companyId} agreementId={obj._id} from={obj.activeFrom} to={obj.activeTo} />
                    </td>
                  </tr>
                )
            )))}

        </tbody>
      </table>
    </TableSection>
  );
}

CompanyAgreementsTable.propTypes = {
  cityId: PropTypes.string.isRequired,
  companyId: PropTypes.string.isRequired,
};

export default CompanyAgreementsTable;
