import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import { addUserMutation, updateUserMutation, getUsersQuery, updateUserPasswordMutation } from '../../queries/queries';
import { Form, Field, Group, Button } from '../../common/index';

function UserForm(props) {
  const { content, onComplete } = props;

  const [sContent, setSContent] = useState(content);
  const [newPassword, setNewPassword] = useState('');
  const [updateUserPassword, { loading }] = useMutation(updateUserPasswordMutation);

  const handleSubmit = async (event) => {
    event.preventDefault();
    console.log('klick')

    if (!newPassword || newPassword.length < 6) {
      alert('Nytt lösenord måste vara mer än 6 tecken')
      return null;
    }
    if (!content?._id) {
      alert('Saknar content-id', content)
      return null;
    }
    try {
      await updateUserPassword({
        variables: {
          password: newPassword,
          _id: content._id
        },
      });
      alert('Password updated successfully!');
    } catch (error) {
      console.error(error);
      alert('Password update failed!');
    }
  };

  const ss = (c) => {
    setSContent({ ...sContent, ...c })
    console.log(c)
  };


  const verify = (v) => {
    // const tempPassword = event.target ? event.target.value : event; // check if e.target exists
    setNewPassword(v);
  }

  console.log(newPassword)

  return (
    <tr key={content ? content._id : 'new'} className="open">
      <td colSpan="100">
        <Form sContent={sContent} parent="userId" get={getUsersQuery} add={addUserMutation} update={updateUserMutation} onComplete={onComplete}>
          <Group title="Allmänt">
            <Field type="text" req name="firstname" title="Förnamn" value={sContent.firstname} onChange={ss} />
            <Field type="text" req name="lastname" title="Efternamn" value={sContent.lastname} onChange={ss} />
          </Group>
          <Group title="Inställningar">
            <Field type="email" req name="email" title="E-postadress" value={sContent.email} onChange={ss} />
            <Field type="selectYesOrNo" name="superAdmin" title="superAdmin" value={sContent && sContent.active} selectDataInfo={{ loading: false }} selectData={[{ _id: true, name: 'Ja' }, { _id: false, name: 'Nej' }]} onChange={ss} />
          </Group>

        </Form>
        <form>
          <Group title='Ändra lösenord' >

            <input type="password" name="password" title="Nytt lösenord" value={newPassword} onChange={(v) => { verify(v.target.value) }} />
            <Button onClick={handleSubmit} disabled={loading} className='primary' size='xs'>Spara nytt lösenord</Button>

          </Group>

        </form>

      </td>
    </tr>
  );
}

UserForm.propTypes = {
  content: PropTypes.object.isRequired,
  onComplete: PropTypes.func.isRequired,
};

export default UserForm;
