import React from 'react'
import TeamMembersTable from './TeamMembersTable'
import { useQuery } from '@apollo/client';
import { getTeamNameQuery, getTeamQuery, getAssociationPublicQuery } from '../../../queries/queries';
import { PageHeader } from '../../../common/index'
import TeamMembersStatistics from './TeamMembersStatistics';
import { useNavigationContext } from '../../../context/NavigationContext';

function DashboardTeam(props) {
  const { association: associationId, team: teamId } = props.match.params;
  const { data: associationData } = useQuery(getAssociationPublicQuery, { variables: { associationId: associationId } })
  const { data: teamName } = useQuery(getTeamNameQuery, { variables: { teamId: teamId } })
  const { data: teamData, refetch: refetchTeam } = useQuery(getTeamQuery, { variables: { teamId: teamId } })

  const { setNavigationActivated } = useNavigationContext();
  React.useEffect(() => {
    setNavigationActivated(true);
  }, []);

  React.useEffect(() => {
    teamData?.team && console.log('Lag', teamData?.team);
    teamData?.team?.adminUsers && console.log('Lagledare', teamData?.team?.adminUsers);
  }, [teamData?.team?.adminUsers]);

  return (
    <div id="teamteam">
      <PageHeader>
        <h1>{teamData && teamData.team.name}</h1>
        <p>{associationData?.association.name}</p>
      </PageHeader>
      <TeamMembersStatistics
        associationId={associationId}
        teamId={teamId}
      />
      <TeamMembersTable
        associationId={associationId}
        teamName={teamName}
        teamId={teamId}
        refetchTeam={refetchTeam}
      />
    </div>
  )
}

export default DashboardTeam
