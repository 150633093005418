import React from 'react'
import close from '../../../img/deelo_close.svg';
import { Button } from '../../../common/index'
import { inputValidator, useAutosize } from '../../../common/Helpers';
import envelope from '../../../img/envelope.svg';
import { useMutation } from '@apollo/client';
import { deleteTeamMemberAdminInvitation, inviteTeamMemberAdmin } from '../../../queries/invitations';
import Resend from '../../../img/send_fill.svg'
import Delete from '../../../img/deelo_close.svg'
import removeCircle from '../../../img/remove-circle-icon.svg'

const { REACT_APP_BASE_URL, REACT_APP_BRAND } = process.env;
function ModalTeamMember({ user, teammember, setManage, refetchTeamMembers }) {
  const possessionType = JSON.parse(localStorage.getItem('POSSESSION'))?.type;

  const { height } = useAutosize();
  const [overflow, setOverflow] = React.useState(false);
  const [resend, setResend] = React.useState();
  const [remove, setRemove] = React.useState();
  const [errorVisible, setErrorVisible] = React.useState(false);
  const [guardianAdd, setGuardianAdd] = React.useState();
  const [emailValidation, setEmailValidation] = React.useState(inputValidator('email', ''));
  const [email, setEmail] = React.useState('')
  const boxRef = React.useRef();

  const [resentInvitation, setResentInvitation] = React.useState(false);
  const [removedInvitation, setRemovedInvitation] = React.useState(false);
  const [deleteInvitation, { removeError }] = useMutation(deleteTeamMemberAdminInvitation, {
    onCompleted: (res) => {
      console.log('Inbjudan är nu borttagen', res);
      setRemovedInvitation(true);
      refetchTeamMembers();
    },
    onError: (err) => console.log(err)
  })
  const [invitationTeammemberAdmin, { error: invitationError }] = useMutation(inviteTeamMemberAdmin, {
    onCompleted: (res) => {
      console.log('Inbjudan skickad till målsman/ansvarig', res);
      setResentInvitation(true);
      refetchTeamMembers();
    },
    onError: (err) => console.log(err)
  });

  const resendInvitation = (e) => {
    e.preventDefault();
    if (!resentInvitation) {
      deleteInvitation({
        variables: {
          email: resend?.sentToEmail,
          teamMemberId: teammember._id
        }
      }).then(() => {
        invitationTeammemberAdmin({
          variables: {
            email: resend?.sentToEmail,
            teamMemberId: teammember._id,
            portalUrlForEmails: `${REACT_APP_BASE_URL}/`
          }
        })
      }).catch(err => console.log(err))
    }
  };

  const add = (e) => {
    e.preventDefault();
    if (emailValidation.error) {
      console.log('error');
      setErrorVisible(true);
      return;
    }
    invitationTeammemberAdmin({
      variables: {
        email: email,
        teamMemberId: teammember._id,
        portalUrlForEmails: `${REACT_APP_BASE_URL}/`,
      }
    }).then(() => {
      setEmail('');
      setGuardianAdd(false);
    })
  }

  React.useEffect(() => {
    if (boxRef?.current?.clientHeight / height >= 0.9) {
      setOverflow(true);
      document.querySelector('body').style.cssText = 'overflow: hidden;';
    } else {
      setOverflow(false);
      document.querySelector('body').style.cssText = '';
    }
  }, [height]);

  return (
    <div className={`open modal-form modal modal-team-member ${overflow ? 'autosize' : ''}`}>
      <div ref={boxRef} className={`box ${overflow ? 'autosize' : ''}`}>
        <div className="close" onClick={() => setManage(false)}><img src={close} alt="" /></div>
        {REACT_APP_BRAND !== 'SAENKELT'
          ? <>
            {resend?.sentToEmail
              ? <div className='resend-invitation'>
                <h3>Vill du skicka om inbjudan till {resend.sentToEmail}?</h3>
                {invitationError && invitationError.graphQLErrors.map(({ message }, i) => (
                  <p className="error" key={`invitationError${i}`}>{message}</p>
                ))}
                {removeError && removeError.graphQLErrors.map(({ message }, i) => (
                  <p className="error" key={`invitationError${i}`}>{message}</p>
                ))}
                <Button className='lg primary' onClick={(e) => resendInvitation(e)}>{!resentInvitation ? 'Skicka om inbjudan' : 'Inbjudan har skickats!'}<img src={envelope} alt="" /></Button>

              </div>
              : remove?.sentToEmail
                ? <div className='delete-invitation'>
                  <h3>Vill du inaktivera och ta bort inbjudan till {remove.sentToEmail}?</h3>
                  {removeError && removeError.graphQLErrors.map(({ message }, i) => (
                    <p className="error" key={`invitationError${i}`}>{message}</p>
                  ))}
                  <Button className='lg primary' onClick={(e) => {
                    if (!removedInvitation) {
                      deleteInvitation({ variables: { email: remove.sentToEmail, teamMemberId: teammember._id } })
                    }
                  }}>{!removedInvitation ? 'Ta bort inbjudan' : 'Inbjudan är borttagen!'}</Button>
                </div>
                : <>
                  <ul>
                    <li className={`${!teammember.isApprovedByGuardian ? 'inactive' : ''}`}>
                      Lagmedlem
                      <p>{teammember.firstname} {teammember.lastname}</p>
                    </li>
                    <li className={`${!teammember.isApprovedByGuardian ? 'inactive' : ''}`}>
                      Sålda häften
                      <p>{teammember.soldBooklets}</p>
                    </li>
                    {/* ADMINS */}
                    {teammember.isApprovedByGuardian && teammember?.adminEmails?.length > 0 &&
                      <li>
                        Målsmän
                        {teammember?.adminEmails?.map((email, index) => (
                          <p key={index}>{email}</p>
                        ))}
                      </li>
                    }
                    {/* INVITATIONS */}
                    {teammember?.adminInvitations?.length > 0 &&
                      <li className=''>
                        <span className='inactive'>{teammember.isApprovedByGuardian ? 'Icke registrerade målsmän' : 'Inväntar godkännande av'}</span>
                        {teammember?.adminInvitations?.map((invitation, index) => (
                          <div key={index} className='handle-invitations'>
                            <p className='inactive'>{invitation.sentToEmail}</p>
                            <button className="resend" onClick={() => setResend(invitation)} title={'Skicka om inbjudan'}>
                              <img src={Resend} alt="" />
                            </button>
                            <button className="delete" onClick={() => setRemove(invitation)} title={'Ta bort inbjudan'}>
                              <img src={Delete} alt="" />
                            </button>
                          </div>
                        ))}
                      </li>
                    }
                  </ul>
                  {!guardianAdd
                    ? <button className="bg-none" onClick={() => setGuardianAdd(true)}>Lägg till en målsman +</button>
                    : <form noValidate onSubmit={add} className='admin-add'>
                      <div className="form-row relative">
                        <label htmlFor={'email'}>Målsmans e-post</label>
                        <Button onClick={() => setGuardianAdd(false)} className='bg-none m-0 email-decrement'>
                          <img src={removeCircle} alt="" />
                        </Button>
                        <input type="email" id="email"
                          onChange={(e) => {
                            setEmailValidation(inputValidator('email', e.target.value))
                            setEmail(e.target.value)
                          }}
                          value={email} autoComplete="off"
                        />
                        {!emailValidation?.valid && errorVisible
                          ? <small className="error">{emailValidation?.error}</small>
                          : invitationError && invitationError.graphQLErrors.map(({ message }, i) => (
                            <p className="submit-error" key={i}>{message}</p>
                          ))
                        }

                      </div>
                      <Button className='lg primary' type='submit'>
                        Lägg till och bjud in
                        <img src={envelope} alt="" />
                      </Button>
                    </form>
                  }
                </>
            }
          </>
          : <ul>
            <li className={`${!teammember.isApprovedByGuardian ? 'inactive' : ''}`}>
              Lagmedlem
              <p>{teammember.firstname} {teammember.lastname}</p>
            </li>
            <li className={`${!teammember.isApprovedByGuardian ? 'inactive' : ''}`}>
              Sålda häften
              <p>{teammember.soldBooklets}</p>
            </li>
            <li>
              Säljarens webbshop är skapad av
              {teammember?.adminEmails?.map((email, index) => (
                <p key={index}>{email}</p>
              ))}
            </li>
          </ul>
        }
      </div>
    </div>
  )
}

export default ModalTeamMember
