import React from 'react';
import close from '../img/deelo_close.svg';

function Modal({ children, isOpen, closeModal, className }) {

  if (!isOpen) {
    return null;
  }

  const handleClickOutside = (e) => {
    if (closeModal && e.target.classList.contains('modal')) {
      closeModal();
    }
  }

  return (
    <div className={`modal ${className}`} onClick={handleClickOutside}>
      <div className="box">
        {closeModal && (
          <div className="close" onClick={closeModal}>
            <img src={close} alt="" />
          </div>
        )}
        {children}
      </div>
    </div>
  )
}
export default Modal
