import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { addTeamMemberMutation, updateTeamMemberMutation, getTeamMembersQuery } from '../../../queries/queries';
import { Form, Field, Group } from '../../../common/index';
// import { useMutation } from '@apollo/client';

function TeamMemberForm(props) {
  const { content, onComplete } = props;
  const [sContent, setSContent] = useState(content);

  const ss = (c) => setSContent({ ...sContent, ...c });

  return (
    <tr key={content ? content._id : 'new'} className="open">
      <td colSpan="100">
        <Form sContent={sContent} parent="teamId" get={getTeamMembersQuery} add={addTeamMemberMutation} update={updateTeamMemberMutation} onComplete={onComplete}>
          <Group title="Allmänt">
            <Field type="text" req name="firstname" title="Förnamn" value={sContent.firstname} onChange={ss} />
            <Field type="text" req name="lastname" title="Efternamn" value={sContent.lastname} onChange={ss} />
          </Group>
          <Group title="">
            <Field type="admins" x2 name="adminEmails" title="Målsman/Ansvarig" value={sContent.adminEmails} onChange={ss} />
          </Group>
        </Form>
      </td>
    </tr>
  );
}

TeamMemberForm.propTypes = {
  content: PropTypes.object.isRequired,
  onComplete: PropTypes.func.isRequired,
};

export default TeamMemberForm;
