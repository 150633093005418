import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import { ongoingPeriodsInCompanyQuery, statOrdersByCityQuery, statCouponsByCompanyQuery } from '../../queries/queries';
import { StatsSection, StatsBox, StatsListItem, StatsNumber } from '../../common/index';

// function statsVariables(associationId, periods, currentPeriod) {
//   return {
//     variables: {
//       associationId,
//       fromPeriod: currentPeriod === 'all' ? (periods && periods.fromPeriod) : currentPeriod,
//       toPeriod: currentPeriod === 'all' ? (periods && periods.toPeriod) : currentPeriod,
//     },
//   };
// }

function CompanyAgreementsStatistics(props) {
  const { cityId, companyId } = props;
  const [period, setPeriod] = useState();
  //  const statsVars = statsVariables(associationId, agreementPeriods, period);


  const { data: agreementPeriods } = useQuery(ongoingPeriodsInCompanyQuery, {
    variables: {
      companyId
    }
  });

  React.useEffect(() => {
    agreementPeriods && setPeriod(agreementPeriods.periods.currentPeriod)
  }, [agreementPeriods])

  const { data: statsCityOrders } = useQuery(statOrdersByCityQuery, {
    variables: {
      cityId,
      fromPeriod: period,
      toPeriod: period
    }
  });
  const { data: statsCouponsCompany } = useQuery(statCouponsByCompanyQuery, {
    variables: {
      companyId,
      fromPeriod: period,
      toPeriod: period
    }
  });

  return (
    <StatsSection agreementPeriods={agreementPeriods} currentPeriod={period} setPeriod={(p) => setPeriod(p)}>
      <StatsBox type="number" title="Sålda häften" corner="icon wow">
        <StatsNumber value={statsCityOrders && statsCityOrders.orders && statsCityOrders.orders.totalNumber} />
      </StatsBox>
      <StatsBox type="number" title="Aktiverade avtalsrabatter" corner="icon superb">
        <StatsNumber value={statsCouponsCompany && statsCouponsCompany.coupons && statsCouponsCompany.coupons.used.agreement} />
      </StatsBox>
      <StatsBox type="list" title="Aktiverade säsongsrabatter" corner={
        (statsCouponsCompany && statsCouponsCompany.coupons && statsCouponsCompany.coupons.used.season1) +
        (statsCouponsCompany && statsCouponsCompany.coupons && statsCouponsCompany.coupons.used.season2) +
        (statsCouponsCompany && statsCouponsCompany.coupons && statsCouponsCompany.coupons.used.season3)}>
        <StatsListItem title="Rabatt 1" value={statsCouponsCompany && statsCouponsCompany.coupons && statsCouponsCompany.coupons.used.season1} />
        <StatsListItem title="Rabatt 2" value={statsCouponsCompany && statsCouponsCompany.coupons && statsCouponsCompany.coupons.used.season2} />
        <StatsListItem title="Rabatt 3" value={statsCouponsCompany && statsCouponsCompany.coupons && statsCouponsCompany.coupons.used.season3} />
      </StatsBox>
    </StatsSection>
  );
}

CompanyAgreementsStatistics.propTypes = {
  companyId: PropTypes.string.isRequired,
  cityId: PropTypes.string,
};

CompanyAgreementsStatistics.defaultProps = {
};

export default CompanyAgreementsStatistics;
