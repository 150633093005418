import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import { getCouponPositionsQuery, getCouponsQuery, addCouponMutation, updateCouponMutation } from '../../queries/queries';
import { Form, Field, Group } from '../../common/index';

const codeTypeOptions = [
  { value: 'TEXT', label: 'TEXT' },
  { value: 'QR', label: 'QR' },
  { value: 'CODE39', label: 'CODE39' },
  { value: 'EAN13', label: 'EAN13' },
  { value: 'EAN8', label: 'EAN8' },
  { value: 'EAN5', label: 'EAN5' },
  { value: 'EAN2', label: 'EAN2' },
  { value: 'UPCA', label: 'UPCA' },
  { value: 'UPCE', label: 'UPCE' },
  { value: 'CODE128', label: 'CODE128' },
  { value: 'CODE128A', label: 'CODE128A' },
  { value: 'CODE128B', label: 'CODE128B' },
  { value: 'CODE128C', label: 'CODE128C' },
  { value: 'ITF', label: 'ITF' },
  { value: 'ITF14', label: 'ITF14' },
  { value: 'MSI10', label: 'MSI10' },
  { value: 'MSI11', label: 'MSI11' },
  { value: 'MSI1010', label: 'MSI1010' },
  { value: 'MSI1110', label: 'MSI1110' },
  { value: 'pharmacode', label: 'pharmacode' },
  { value: 'codabar', label: 'codabar' },
  { value: 'NONE', label: 'NONE' },
];

function CouponsForm(props) {
  const { content, onComplete } = props;
  const { data: positionsQuery, loading } = useQuery(getCouponPositionsQuery);

  const isNewCoupon = !content || !content._id;

  const [sContent, setSContent] = useState({
    ...content,
    terms: isNewCoupon ? 'Kan ej kombineras med andra erbjudanden.' : content.terms,
    codeType: isNewCoupon ? 'TEXT' : content.codeType,
  });

  const ss = (c) => setSContent({ ...sContent, ...c });

  return (
    <tr key={content ? content._id : 'new'} className="open">
      <td colSpan={4}>
        <Form sContent={sContent} parent="companyId" get={getCouponsQuery} add={addCouponMutation} update={updateCouponMutation} onComplete={onComplete}>
          <Group title="Texter">
            <Field type="text" req name="discount" title="Rabatt *" value={sContent && sContent.discount} onChange={ss} />
            <Field type="number" req name="estimatedSavings" title="Beräknad besparing *" value={sContent && sContent.estimatedSavings} onChange={ss} />
            <Field type="text" req name="smallTitle" title="Liten titel *" value={sContent && sContent.smallTitle} onChange={ss} />
            <Field type="text" req x2 name="terms" title="Villkor titel" value={sContent && sContent.terms} onChange={ss} />
            <Field type="text" req x2 name="description" title="Beskrivning" value={sContent && sContent.description} onChange={ss} />
          </Group>
          <Group title="Inställningar">
            <Field type="select" name="position" title="Position" value={sContent && sContent.position} selectDataInfo={positionsQuery} isDataLoading={loading} selectData={positionsQuery?.positions} onChange={ss} />
            <Field type="image" name="imageURL" title="Bild (max 1MB)" value={sContent && sContent.imageURL} onChange={ss} />
            <Field type="text" name="code" title="Rabattkod" value={sContent && sContent.code} onChange={ss} />
            <Field type="text" name="productLink" title="Produkt-URL" value={sContent && sContent.productLink} onChange={ss} />
            <Field type="selectStatic" name="codeType" title="Kodtyp" value={sContent && sContent.codeType} selectData={codeTypeOptions} onChange={ss} />
          </Group>
        </Form>
      </td>
    </tr>
  );
}

CouponsForm.propTypes = {
  content: PropTypes.object.isRequired,
  onComplete: PropTypes.func.isRequired,
};

CouponsForm.defaultProps = {
  content: null,
};

export default CouponsForm;
