import React, { useState } from 'react'
import { Button } from '../../common/Form';
import { generateCompletedOrderPDF, generateCompletedOrderBatchPDF, getBookletNameQuery } from '../../queries/booklet';
import { useMutation, useQuery } from '@apollo/client';

const OrderBatchDownloadPDF = ({ bookletId, batchLabel, orderId, orderCode, isEmailView }) => {
  const [generateBatchPDF, { loading: isPdfBatchMutationLoading }] = useMutation(generateCompletedOrderBatchPDF);
  const [generateOrderPDF, { loading: isOrderPdfMutationLoading }] = useMutation(generateCompletedOrderPDF);
  const [loading, setLoading] = useState('');

  const { data: bookletData } = useQuery(getBookletNameQuery, {
    variables: { _id: bookletId },
    skip: !bookletId
  });

  const downloadAsPDF = async (mode) => {
    if (isPdfBatchMutationLoading || isOrderPdfMutationLoading) return;

    setLoading(mode);
    try {
      let base64Data;

      if (mode === 'single') {
        const response = await generateOrderPDF({
          variables: {
            bookletId,
            orderId,
            encryptedOrderCode: orderCode,
          }
        });
        base64Data = response.data.generateCompletedOrderPDF;
      } else {
        const response = await generateBatchPDF({
          variables: {
            batchLabel,
            bookletId,
            mode,
          }
        });
        base64Data = response.data.generateCompletedOrderBatchPDF;
      }

      let blob;
      const fileName = `Värdebevis - ${bookletData?.booklet?.title || ''}.${mode === 'separated' ? 'zip' : 'pdf'}`;

      // Handle data as a ZIP file
      if (mode === 'separated') {
        const binaryString = window.atob(base64Data);
        const len = binaryString.length;
        const bytes = new Uint8Array(len);
        for (let i = 0; i < len; i++) {
          bytes[i] = binaryString.charCodeAt(i);
        }
        blob = new Blob([bytes], { type: 'application/zip' });
      } else {
        // Handle data as a PDF file
        const asciiValues = base64Data.split(',').map(value => parseInt(value, 10));
        const bytes = new Uint8Array(asciiValues);
        blob = new Blob([bytes], { type: 'application/pdf' });
      }

      // Create and download file
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = fileName;
      document.body.appendChild(link);
      link.click();

      // Clean up
      document.body.removeChild(link);
      window.URL.revokeObjectURL(link.href);

      setLoading('');
    } catch (err) {
      console.error('Error generating PDF:', err);
      setLoading('');
    }
  };

  return (
    <div>
      <div className={`flex flex-col max-w mb ${isEmailView ? 'gap-1' : ''}`}>
        {orderId ? (
          <Button
            type='button'
            onClick={() => downloadAsPDF('single')}
            className="primary md"
            disabled={loading === 'single'}
          >
            {loading === 'single' ? 'Laddar... (ca 15 sek)' : 'Ladda ned PDF'}
          </Button>
        ) : (
          <>
            <Button type='button' onClick={() => downloadAsPDF('combined')} className="primary md" disabled={loading === 'combined' || loading === 'separated'}>
              {loading === 'combined' ? 'Laddar... (ca 15 sek)' : 'Ladda ned en PDF med alla värdebevis'}
            </Button>
            <Button type='button' onClick={() => downloadAsPDF('separated')} className="primary md" disabled={loading === 'separated' || loading === 'combined'}>
              {loading === 'separated' ? 'Laddar... (1 - 10 min)' : 'Ladda ned separata PDF:er (1 - 10 min)'}
            </Button>
          </>
        )}
      </div>
    </div>
  )
}

export default OrderBatchDownloadPDF
