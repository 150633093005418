/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { activateAccountMutation, loginMutation } from '../../queries/queries';
import { Field, Button } from '../../common/index';
import { useNavigationContext } from '../../context/NavigationContext';

const { REACT_APP_BRAND } = process.env;

function AuthActivateAccountForm(props) {
  const { updateAuthTokenCallback } = props;
  const { email, temppassword } = props.match.params;
  const [user, setUser] = useState({ password: '', firstname: '', lastname: '' });
  const [redirectToLogin, setRedirectToLogin] = useState(false);
  const [activateAccount, { loading: activateAccountLoading, error: activateAccountError }] = useMutation(activateAccountMutation);
  const [login] = useMutation(loginMutation);//, options);

  const { setNavigationActivated } = useNavigationContext();
  React.useEffect(() => {
    setNavigationActivated(true);
  }, []);

  function submitForm(e) {
    e.preventDefault();

    if (activateAccountLoading) {
      console.log('Loading. Double submit canceled.')
      return null;
    }

    const vars = {
      variables: {
        email: email,
        tmpPassword: temppassword,
        newPassword: user.password,
        firstname: user.firstname,
        lastname: user.lastname,
        longExpiry: false,
        termsApproved: true,
      },
    };

    activateAccount(vars)
      .then(result => {
        // console.log('result', result);
        // setRedirectToLogin(true);
        const loginVars = {
          variables: {
            email: email,
            password: user.password,
            longExpiry: false,
          },
        };
        login(loginVars)
          .then(result => { updateAuthTokenCallback(result && result.data.login); console.log('result && result.data.login', result && result.data.login); })
          .catch(useMutationError => { console.log('regErr', useMutationError, useMutationError && useMutationError.graphQLErrors) });
      })
      .catch(useMutationError => { console.log('regErr', useMutationError, useMutationError && useMutationError.graphQLErrors) });
  }

  const ss = (v) => setUser({ ...user, ...v });

  return (
    <div id="login">
      {redirectToLogin && <Redirect to="/activated" />}
      <header className="login-header">
        <h1 className="mh-logo">{REACT_APP_BRAND === 'CITY' ? 'Cityhäftet' : 'Så Enkelt'}</h1>
      </header>
      <div className="left">
        <div className="box">
          <h3>Aktivera konto för</h3>

          <form id="login-form" onSubmit={(e) => { submitForm(e); }}>

            <br />
            {console.log('activateAccountLoading', activateAccountLoading)}

            <p className="small-label">Användarnamn:</p>
            <p className="text">{email}</p>
            <Field type="text" name="firstname" title="Förnamn" value={user.firstname} onChange={ss} />
            <Field type="text" name="lastname" title="Efternamn" value={user.lastname} onChange={ss} />
            <Field type="password" x2 name="password" title="Ange ett lösenord" value={user.password} onChange={ss} />

            {activateAccountError && activateAccountError.graphQLErrors.map(({ message }, i) => (
              <p className="error" key={i}>{message}</p>
            ))}
            <Button className="primary lg" loading={activateAccountLoading} type="submit">Aktivera</Button>

          </form>
        </div>
      </div>
      <div className="right" />
    </div>
  );
}

AuthActivateAccountForm.propTypes = {
  updateAuthTokenCallback: PropTypes.func.isRequired,
};

AuthActivateAccountForm.defaultProps = {
};

export default AuthActivateAccountForm;
