import React, { useEffect, useState } from 'react';
import { GrClose } from 'react-icons/gr';
import { FaCheck } from 'react-icons/fa';
import { ImEye } from 'react-icons/im';
import { FaPen } from 'react-icons/fa';
import { useMutation, useQuery } from '@apollo/client';
import { getAdminableBookletsQuery, updateCouponsInBookletMutation } from '../../queries/booklet';
import { addBookletCompanyReviewMutation, getCompanyBookletReviewQuery, updateBookletCompanyReviewMutation } from '../../queries/bookletCompanyReview';
import { usePreviewContext } from '../../context/PreviewContext';
import { MdSearch } from 'react-icons/md';
import { CgSpinner } from 'react-icons/cg';

function BookletCouponsReviewPopup(props) {
  const { coupons, booklet, adminEmails, zoneId, isCompanyInView } = props;

  const [updateCouponsInBooklet] = useMutation(updateCouponsInBookletMutation);
  const [addReview] = useMutation(addBookletCompanyReviewMutation);
  const [updateReview] = useMutation(updateBookletCompanyReviewMutation)
  const { data: reviewData, refetch: refetchReview, loading: isReviewLoading } = useQuery(getCompanyBookletReviewQuery, {
    variables: {
      companyId: coupons[0]?.companyId,
      bookletId: booklet?._id
    },
    skip: !isCompanyInView,
    fetchPolicy: 'network-only'
  });

  const presetMessage = 'Tack och grattis till ert deltagande i vårt digitala rabatthäfte.\n\n' +
    'För att säkerställa att era kuponger blir korrekta ber vi er granska dem snarast. Det tar bara 1 min!\n\n' +
    'Om ni inte inkommer med ett svar inom 7 dagar antar vi att kupongerna är korrekta och publicerar dem i häftet.'

  const [isInFocus, setIsInFocus] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [checkedCouponIds, setCheckedCouponIds] = useState([]);
  const [messageToCompany, setMessageToCompany] = useState(presetMessage);
  const [couponsAwaitingReview, setCouponsAwaitingReview] = useState([]);
  const [couponsWithComment, setCouponsWithComment] = useState([]);
  const [couponsApproved, setCouponsApproved] = useState([]);

  const review = reviewData?.bookletCompanyReview;

  useEffect(() => {
    if (review?.approvedDate) {
      setCouponsApproved(review.coupons.map(coupon => coupon._id))
    }
    else if (review?.comment) {
      setCouponsWithComment(review.coupons.map(coupon => coupon._id))
    }
    else if (review?.sentToEmails) {
      setCouponsAwaitingReview(review.coupons.map(coupon => coupon._id))
    }
    else {
      setCouponsApproved([]);
      setCouponsWithComment([]);
      setCouponsAwaitingReview([]);
    }

  }, [review]);

  // functions 
  const handleMessageChange = (event) => {
    setMessageToCompany(event.target.value)
  }
  const handleOpen = () => {
    setIsOpen(true);
    setIsInFocus(false);
  }

  const handleCloseReviewPopup = () => {
    setIsOpen(false);
    setIsInFocus(false);
    setCheckedCouponIds([]);
    setMessageToCompany(presetMessage);
  }

  const handleCheckbox = (e) => {
    const isChecked = e.target.checked;
    const id = e.target.name;

    if (isChecked) {
      setCheckedCouponIds((prevChecked) => [...prevChecked, id]);
    } else {
      setCheckedCouponIds(checkedCouponIds.filter((couponId) => couponId !== id));

      if (checkedCouponIds.length === 1) {
        handleCloseReviewPopup();
      }
    }
  }

  const handleClickActivateBtn = () => {
    const previousCoupons = [...booklet?.couponIds];
    const couponsToAdd = [...checkedCouponIds.filter(coupon => !previousCoupons.includes(coupon))]
    const couponsMerged = previousCoupons.concat(couponsToAdd);

    updateCouponsInBooklet({
      variables: {
        _id: booklet._id,
        title: booklet.title,
        couponIds: couponsMerged
      },
      refetchQueries: [
        {
          query: getAdminableBookletsQuery, variables: {
            zoneId: zoneId
          }
        }
      ]
    }).catch(error => {
      console.error('Error updating booklet: ',)
    });
    handleCloseReviewPopup();
  }

  const handleClickDeactivateBtn = () => {
    const previousCoupons = [...booklet?.couponIds];
    const couponsToInclude = previousCoupons.filter(coupon => !checkedCouponIds.includes(coupon));

    updateCouponsInBooklet({
      variables: {
        _id: booklet._id,
        title: booklet.title,
        couponIds: couponsToInclude
      },
      refetchQueries: [
        {
          query: getAdminableBookletsQuery, variables: {
            zoneId: zoneId
          }
        }
      ]
    }).catch(error => {
      console.error('Error updating booklet: ',)
    });
    handleCloseReviewPopup();

  }

  const handleClickSendEmailReview = async () => {
    const formatedMsg = messageToCompany.replace(/\n\n/g, '<br><br>')

    await addReview({
      variables: {
        bookletId: booklet._id,
        companyId: coupons[0]?.companyId,
        couponIds: checkedCouponIds,
        shouldSendMail: true,
        sentToEmails: adminEmails,
        messageToCompany: formatedMsg
      }
    }).then(() => {
      refetchReview();
      setMessageToCompany(presetMessage);
      handleCloseReviewPopup();
    }).catch(error => {
      console.error('Error creating review:', error)
      alert('Någonting gick fel när review skulle skickas!');
    });

  }

  const handleClickApproveReview = async () => {
    const resolved = await addReview({
      variables: {
        bookletId: booklet._id,
        companyId: coupons[0]?.companyId,
        couponIds: checkedCouponIds
      }
    }).catch(error => {
      console.error('Error creating review')
    });

    const id = resolved.data.createBookletCompanyReview._id;

    const isoDateString = new Date().toISOString();

    updateReview({
      variables: {
        _id: id,
        approvedDate: isoDateString
      }
    }).catch(error => {
      console.error('Error updating review in handleClickApproveReview: ', error)
    }).then(() => {
      refetchReview();
    })
    handleCloseReviewPopup();
  }

  const handleClickCancelReview = async () => {

    const id = review?._id

    updateReview({
      variables: {
        _id: id,
        isActive: false
      },
      refetchQueries: [
        { query: getCompanyBookletReviewQuery, variables: { companyId: coupons[0]?.companyId, bookletId: booklet?._id } }
      ]
    }).catch(error => {
      console.error('Error updating review in handleClickCancelReview: ', error)
    })
  }

  return (
    <>
      {isOpen && <div className='pop-up-overlay'></div>}

      {/* Review popup container */}
      <div className={`pop-up-btn ${isInFocus ? 'hovered' : ''} ${isOpen ? 'open' : ''}`}
        onMouseEnter={() => setIsInFocus(true)}
        onMouseLeave={() => setIsInFocus(false)}
      >
        <PreviewButton booklet={booklet} companyId={coupons[0].companyId}></PreviewButton>
        {coupons.map((coupon, index) => (
          <div
            key={index}
            className={`pop-up-status-box ${booklet?.couponIds?.includes(coupon._id) ? 'included' : ''}`}
          >
            {(isReviewLoading || !reviewData) && isCompanyInView && <CgSpinner />}
            {couponsApproved.includes(coupon._id) && <FaCheck />}
            {couponsAwaitingReview.includes(coupon._id) && <ImEye />}
            {couponsWithComment.includes(coupon._id) && <FaPen />}
            <input type="checkbox"
              className={`pop-up-checkbox ${isInFocus ? 'hovered' : ''} ${isOpen ? 'open' : ''}`}
              name={coupon._id}
              checked={checkedCouponIds.includes(coupon._id)}
              onChange={(e) => handleCheckbox(e)}
              onClick={() => handleOpen()} />
          </div>
        ))}
      </div>

      {/* Review popup content */}
      {isOpen &&
        <div className='pop-up review-pop-up'>
          <div className='section'>
            <h4>Kommentar från företag</h4>
            <div className='comment-box'>{review?.comment}</div>
          </div>
          <div className='section'>
            <h3>Ställ in manuellt</h3>
            <button className="secondary" onClick={() => handleClickDeactivateBtn()}>Inaktivera</button>
            <button className="secondary" onClick={() => handleClickActivateBtn()}>Aktivera</button>
            <button className="secondary" onClick={() => handleClickCancelReview()}>Avbryt granskning</button>
            <button className="secondary" disabled={couponsApproved.length >= 1 || couponsAwaitingReview.length >= 1 || couponsWithComment.length >= 1 ? true : false} onClick={() => handleClickApproveReview()}>Markera godkänd</button>
          </div>
          <div className='section'>
            <div className='close'
              onClick={() => handleCloseReviewPopup()}
            ><GrClose /></div>
            <h4>Begär granskning</h4>

            {couponsApproved.length >= 1 || couponsAwaitingReview.length >= 1 || couponsWithComment.length >= 1 ?
              <div style={{ color: 'red', maxWidth: '260px' }} >Granskning pågår! För att skicka en ny granskning eller godkänna kuponger direkt, tryck först "Avbryt granskning".</div>
              :
              <textarea className="comment-box" name="" id="" cols="30" rows="10" value={messageToCompany} onChange={handleMessageChange}></textarea>}
            <button disabled className="primary">Skicka (SMS)</button>
            <button disabled={couponsApproved.length >= 1 || couponsAwaitingReview.length >= 1 || couponsWithComment.length >= 1 ? true : false}
              className="primary"
              onClick={() => handleClickSendEmailReview()}>Skicka email</button>
          </div>
        </div>
      }
    </>
  )
}

const PreviewButton = ({ booklet, companyId }) => {
  const { isPreviewOpen, companyToPreview, setCompanyToPreview } = usePreviewContext();

  const handleSetCompanyPreview = () => {
    setCompanyToPreview({
      companyId: companyId,
      bookletId: booklet._id,
    });
  };

  return (
    <td className="booklet-column preview">
      <div className='flex items-center justify-center'>
        <button
          className={`preview-button ${isPreviewOpen && (companyToPreview?.companyId === companyId?._id && companyToPreview?.bookletId === booklet?._id) ? 'active' : ''}`}
          onClick={handleSetCompanyPreview}
        >
          <MdSearch size={26} />
        </button>
      </div>
    </td>
  );
}

export default BookletCouponsReviewPopup;
