import React from 'react';
import saljare from '../../img/saljare.png';
import lagledare from '../../img/lagledare.png';


const { REACT_APP_BRAND } = process.env;
export default function HowTo({ seller }) {
  return (
    <div className={`card flex items-center ${REACT_APP_BRAND === 'CITY' ? 'cityhaftet' : ''}`}>
      <div>
        <h3>Så köper man {seller ? 'ett häfte' : 'häften'} av {seller ? 'dig' : 'ert lag'}</h3>
        <ol>
          <li>Gå in på <b>shop.saenkelt.se</b></li>
          <li>Välj <b>{seller ? 'ditt lag' : ''}</b> {seller ? 'och' : ''} <b>{seller ? 'dig' : 'ert lag'}</b>  i listan</li>
          <li>Välj häfte och betala</li>
          <li>Ladda ner appen och aktivera häftet</li>
        </ol>
      </div>
      {REACT_APP_BRAND === 'SAENKELT' && <img className='phone-img' src={seller ? saljare : lagledare} alt="bild på telefon" />}
    </div>
  )
}
