import React from 'react'
import { EditButton, useAutosize } from '../../../common/index'
import { useHistory } from 'react-router-dom'
import { getTeamMembersQuery } from '../../../queries/teamMember';
import { useQuery } from '@apollo/client';
import ListNames from '../../../common/ListNames';
import { statOrdersByTeamQuery } from '../../../queries/team';

function Team({ user, associationId, team, setEditing, period }) {
  const { width } = useAutosize()
  const history = useHistory();
  // const [soldBooklets, setSoldBooklets] = React.useState(0);
  const { data: teamMembersData } = useQuery(getTeamMembersQuery, {
    variables: {
      teamId: team._id
    }
  })

  const { data: statsOrderTeams } = useQuery(statOrdersByTeamQuery, {
    variables: {
      teamId: team._id,
      fromPeriod: period,
      toPeriod: period
    }
  });

  return (
    <tr className='c-pointer' onClick={(e) => e.target.nodeName.toLowerCase() !== 'button' && history.push({
      pathname: `/cities/associations/${associationId}/teams/${team._id}/team`,
      state: {
        previousRoute: history.location.pathname
      }
    })}>
      <td>
        {/* <p className={` m-0 arrow ${team?.adminInvitations?.filter(admin => admin.userIdActivated !== null).length > 0 ? '' : 'inactive'}`}> */}
        <p className={` m-0 arrow ${team.adminUsers.length === 0 ? 'inactive' : ''}`}>
          {team.name}
        </p>
        {team.adminInvitations === null && team.adminUsers.length === 0 && <span className='small inactive'>Lagledare saknas</span>}
        {team?.adminUsers?.length > 0 && width < 1000 && <ListNames admins={team?.adminUsers} title={'Ledare'} />}
        {team?.adminInvitations?.filter(admin => admin.userIdActivated === null).length > 0 &&
          <span className='small inactive'>Inväntar {team?.adminInvitations?.filter(admin => admin.userIdActivated === null).length} lagledares aktivering...</span>
        }
        {/* <Values values={team.adminUsers}/> */}
      </td>
      <td>{team?.adminUsers?.length > 0 && <ListNames admins={team?.adminUsers} />}</td>
      <td>
        {user.superAdmin && <EditButton size="xs" className='btn-editing' setEditing={() => setEditing(team._id)}>Redigera</EditButton>}
        <span>{teamMembersData?.teamMembers.length}/{statsOrderTeams && statsOrderTeams.orders.totalNumber}</span>
      </td>
    </tr>
  )
}

export default Team
