import React from 'react'
import OrderBatchDownloadPDF from './OrderBatchDownloadPDF'

const OrderBatchOrder = (props) => {
  const { bookletId, orderId, orderCode } = props.match.params;

  return (
    <div className="h-screen flex flex-col items-center justify-center">
      <h2 className='mb'>Värdebevis</h2>
      <p className='mb-lg text-center'>Här kommer ditt värdebevis för ett häfte fullproppat med fantastiska erbjudanden.</p>
      <OrderBatchDownloadPDF
        isEmailView
        bookletId={bookletId}
        orderId={orderId}
        orderCode={orderCode}
      />
    </div>
  )
}

export default OrderBatchOrder
