import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import PropTypes from 'prop-types';
import { getCompanyQuery } from '../../queries/queries';
import { LoadingTable, EditButton, PeriodById, TableSection } from '../../common/index';
import Form from './CompanyCouponsForm';

const { REACT_APP_IMAGE_BASE_URL } = process.env;

function CompanyCouponsTable(props) {
  const { companyId, agreementId, period } = props;
  const [editing, setEditing] = useState('');
  const mainCoupon = (!period);

  const { data: companyQuery, loading, error } = useQuery(getCompanyQuery, { variables: { id: companyId } });

  return (
    <TableSection>
      <div className="table-header">
        <h2>
          {mainCoupon ? 'Avtalskupongen' : 'Kuponger, '}
          {!mainCoupon && <PeriodById id={period} />}
        </h2>
        {!mainCoupon && <EditButton size="sm add" setEditing={() => setEditing('new')}>Lägg till</EditButton>}
      </div>
      <table>
        <thead>

          <tr>
            <td>Aktiv</td>
            <td>Bild</td>
            <td>Rabatt</td>
            <td>Liten titel</td>
            <td>Villkor</td>
            <td>Beskrivning</td>
          </tr>

        </thead>
        <tbody>

          {editing === 'new'
            && <Form key="new" content={{ companyId, agreementId, period }} onComplete={() => setEditing('')} />}

          {!companyQuery?.company ? <LoadingTable loading={loading} error={error} /> : (
            companyQuery?.company.agreements.map((agreement) => (
              agreementId === agreement._id
              && agreement.coupons.map((obj) => (
                (obj.period === period) && (
                  editing === obj._id
                    ? <Form key={obj._id} content={{ ...obj, companyId, agreementId }} onComplete={() => setEditing('')} />
                    : (
                      <tr key={obj._id}>
                        <td>{obj.active ? 'Ja' : 'Nej'}</td>
                        <td>{obj.imageURL ? <img alt="logo" src={`${REACT_APP_IMAGE_BASE_URL}${obj.imageURL}`} style={{ maxWidth: '100px', maxHeight: '60px' }} /> : ''}</td>
                        <td>{obj.discount}</td>
                        <td>{obj.smallTitle}</td>
                        <td>{obj.terms}</td>
                        <td>{obj.description}</td>
                        <td><EditButton size="xs" setEditing={() => setEditing(obj._id)}>Redigera</EditButton></td>
                      </tr>
                    )
                )
              ))
            )))}

        </tbody>
      </table>
    </TableSection>
  );
}

CompanyCouponsTable.propTypes = {
  companyId: PropTypes.string.isRequired,
  agreementId: PropTypes.string.isRequired,
  period: PropTypes.number,
};

CompanyCouponsTable.defaultProps = {
  period: null,
};

export default CompanyCouponsTable;
