import React, { useEffect, useRef, useState } from 'react'
import { BsChevronCompactRight } from 'react-icons/bs';
import CompanyCoupon from './CompanyCoupon';
import FontIcon from '../../common/FontIcon';
import CompanyModal from './CompanyModal';
import { IoChevronDownSharp, IoChevronUpSharp } from 'react-icons/io5';

const { REACT_APP_IMAGE_BASE_URL } = process.env;

export default function BookletCompany({ company, userOwnsBooklet, companyType, isPrinting, isBookletView, style }) {
  const cardRef = useRef();
  const [isCompanyModalOpen, setIsCompanyModalOpen] = useState(false);
  const [showAllCoupons, setShowAllCoupons] = useState(false);

  useEffect(() => {
    if (isCompanyModalOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }, [isCompanyModalOpen]);

  const handleShowAllCoupons = (e) => {
    e.stopPropagation();
    setShowAllCoupons(!showAllCoupons);
  }

  const handleShowCompanyModal = () => {
    if (isBookletView) {
      setIsCompanyModalOpen(true);
    }
  };

  return (
    <div className={`booklet-company-card ${isBookletView ? 'vara-haften' : ''}`} style={style}>
      <div ref={cardRef} className={`booklet-card`} onClick={handleShowCompanyModal}>
        <div className="left">
          <p className="company-name truncate">{company.name}</p>
          <img className="logo" src={REACT_APP_IMAGE_BASE_URL + company.logoURL} alt='logo' />
          {companyType?.name === 'butik'
            ? <p className='truncate'>
              {company?.visitAddress.streetAddress &&
                <>
                  <span className='font-icon'>
                    <FontIcon iconText='butik' />
                  </span>
                  {company?.visitAddress.streetAddress}
                </>
              }
            </p>
            : company?.websiteURL
              ? <a href={company.websiteURL} target="_blank" rel="noreferrer" className='truncate'><span className='font-icon'><FontIcon iconText='online' /></span>{company.websiteURL}</a>
              : <p></p>
          }
        </div>
        <div className={`right`}>
          <h2 className='truncate'>
            <span className='font-icon'>
              <FontIcon iconText='tag' />
            </span>{company.calculatedCoupons[0].discount}
          </h2>
          <p className="smallTitle truncate">{company.calculatedCoupons[0].smallTitle}</p>
          {
            company.calculatedCoupons.length > 1 &&
            (isPrinting || showAllCoupons
              ? company.calculatedCoupons.slice(1).map((coupon) =>
                <CompanyCoupon key={coupon._id} coupon={coupon} userOwnsBooklet={userOwnsBooklet} />
              )
              : company.calculatedCoupons.slice(1, 4).map((coupon) =>
                <CompanyCoupon key={coupon._id} coupon={coupon} userOwnsBooklet={userOwnsBooklet} />
              )
            )
          }
          {!isPrinting && company.calculatedCoupons.length > 4 &&
            <p className="show-all" onClick={handleShowAllCoupons}>
              {showAllCoupons ? 'Visa färre' : 'Visa alla'}
              {showAllCoupons ? <IoChevronUpSharp /> : <IoChevronDownSharp />}
            </p>
          }
          <BsChevronCompactRight className="arrow" size={30} />
        </div>
      </div>
      <CompanyModal
        company={company}
        companyType={companyType}
        userOwnsBooklet={userOwnsBooklet}
        isCompanyModalOpen={isCompanyModalOpen}
        setIsCompanyModalOpen={setIsCompanyModalOpen}
      />
    </div>
  )
}

