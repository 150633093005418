import React, { ReactNode } from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './index.css';
import { NavigationProvider } from './context/NavigationContext';
import { Buffer } from 'buffer';


const { REACT_APP_BRAND } = process.env;

window.Buffer = Buffer;

const CityTheme: any = React.lazy(() => import('./themes/CityTheme'));
const SaEnkeltTheme: any = React.lazy(() => import('./themes/SaEnkeltTheme'));
const Suspense: any = React.Suspense;

interface ThemeSelectorProps {
  children: ReactNode;
}

const ThemeSelector: React.FC<ThemeSelectorProps> = ({ children }) => {
  console.log(REACT_APP_BRAND)
  return (
    <>
      <Suspense fallback={<></>}>
        {REACT_APP_BRAND === 'CITY' ? <CityTheme /> : <SaEnkeltTheme />}
      </Suspense>
      {children}
    </>
  )
}

ReactDOM.render(
  <ThemeSelector>
    <NavigationProvider>
      <App />
    </NavigationProvider>
  </ThemeSelector>,
  document.getElementById('root'),
)



