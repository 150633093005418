import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';

import { statOrdersByAssociationQuery, statAssociationTopTeamsQuery, statAssociationTopTeamMembersQuery } from '../../../queries/queries';
import { StatsSection, StatsBox, StatsListItem, StatsNumber } from '../../../common/index';

// function statsVariables(associationId, periods, currentPeriod) {
//   return {
//     variables: {
//       associationId,
//       fromPeriod: currentPeriod === 'all' ? (periods && periods.fromPeriod) : currentPeriod,
//       toPeriod: currentPeriod === 'all' ? (periods && periods.toPeriod) : currentPeriod,
//     },
//   };
// }

function TeamsStatistics(props) {
  const { period, setPeriod, agreementPeriods, associationId } = props;
  const [statDates, setStatDates] = useState({ fromDate: null, toDate: null });

  // const [period, setPeriod] = useState();
  //  const statsVars = statsVariables(associationId, agreementPeriods, period);

  // const { data: agreementPeriods } = useQuery(ongoingPeriodsInAssociationQuery, { 
  //   variables: { 
  //     associationId 
  //   } 
  // });

  // React.useEffect(() => {
  //   agreementPeriods && setPeriod(agreementPeriods.periods.currentPeriod)
  // }, [agreementPeriods])

  const { data: statsOrders } = useQuery(statOrdersByAssociationQuery, {
    variables: {
      associationId,
      fromDate: statDates.fromDate ? statDates.fromDate : null,
      toDate: statDates.toDate ? statDates.toDate : null
    }
  });
  const { data: statsTopTeams } = useQuery(statAssociationTopTeamsQuery, {
    variables: {
      associationId,
      fromDate: statDates.fromDate ? statDates.fromDate : null,
      toDate: statDates.toDate ? statDates.toDate : null
    }
  });
  const { data: statsTopTeamMembers } = useQuery(statAssociationTopTeamMembersQuery, {
    variables: {
      associationId,
      fromDate: statDates.fromDate ? statDates.fromDate : null,
      toDate: statDates.toDate ? statDates.toDate : null
    }
  });

  return (
    <StatsSection agreementPeriods={agreementPeriods} currentPeriod={period} setPeriod={(p) => setPeriod(p)} setStatDates={setStatDates} statDates={statDates}>
      <StatsBox type="number" title="Föreningen har sålt" corner="icon wow">
        {statsOrders?.orders?.totalNumber > 0
          ? <StatsNumber value={statsOrders.orders.totalNumber} />
          : <p className='no-sales'>Inga häften sålda under denna period.</p>
        }
      </StatsBox>
      <StatsBox type="list" title="Säljtoppen lag" corner="icon leader">
        {statsOrders?.orders?.totalNumber > 0
          ? <>
            {statsTopTeams && statsTopTeams.teams.teams.map((team) => (
              <StatsListItem key={team.team._id} title={team.team.name} value={team.total} />
            ))}
          </>
          : <p className='no-sales'>Inga häften sålda under denna period.</p>
        }
      </StatsBox>
      <StatsBox type="list" title="Säljtoppen medlemmar" corner="icon hf">
        {statsOrders?.orders?.totalNumber > 0
          ? <>
            {statsTopTeamMembers && statsTopTeamMembers.teamMembers.teamMembers.map((teamMember) => (
              <StatsListItem key={teamMember.teamMember._id} title={`${teamMember.teamMember.firstname} ${teamMember.teamMember.lastname}`} value={teamMember.total} />
            ))}
          </>
          : <p className='no-sales'>Inga häften sålda under denna period.</p>
        }
      </StatsBox>
      {/*<StatsBox type="amount" title="Föreningen har tjänat" corner="icon cash">
      {statsOrders?.orders?.totalNumber > 0 
        ? <StatsAmount value={statsOrders && statsOrders.orders && statsOrders.orders.totalAmount} />
        : <p className='no-sales'>Inga häften sålda under denna period.</p>
      }        
    </StatsBox>*/}
    </StatsSection>
  );
}

TeamsStatistics.propTypes = {
  associationId: PropTypes.string.isRequired,
};

TeamsStatistics.defaultProps = {
};

export default TeamsStatistics;
