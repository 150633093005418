import QRCode from 'qrcode.react';
import React, { useEffect, useState } from 'react'
import { BsChatFill } from 'react-icons/bs';
import { IoIosLink } from 'react-icons/io';
import { IoCloseSharp } from 'react-icons/io5'
import { Mail, Facebook } from 'react-social-sharing';

const { REACT_APP_BASE_URL, REACT_APP_BRAND } = process.env;

function ShareWebshop({ closeModal, associationSlug, teamSlug, teamMemberSlug, isMyWebshop }) {
  const [isShareLinkCopied, setIsShareLinkCopied] = useState(false)
  const [shareLink, setShareLink] = useState('');

  // Set webshop share link based on slugs
  useEffect(() => {
    let link = `${REACT_APP_BASE_URL}/${associationSlug}`;
    if (teamSlug) {
      link += `/${teamSlug}`;
    }
    if (teamMemberSlug) {
      link += `/${teamMemberSlug}`;
    }
    setShareLink(link);
  }, [associationSlug, teamSlug, teamMemberSlug]);

  const copyShareLink = () => {
    navigator.clipboard.writeText(shareLink);
    setIsShareLinkCopied(true);
  }

  const handleClose = () => {
    setIsShareLinkCopied(false);
    closeModal();
  }

  const handleClickOutside = (event) => {
    if (event.target.className === 'modal share-webshop-modal') {
      handleClose();
    }
  }

  return (
    <div className="modal share-webshop-modal" onClick={handleClickOutside}>
      <div className="share-webshop box">
        <IoCloseSharp size={24} className="close" onClick={handleClose} />
        <h3>
          {isMyWebshop ? 'Dela din shop' : 'Dela webbshop'}
        </h3>
        <QRCode
          value={shareLink}
          bgColor={REACT_APP_BRAND === 'SAENKELT' ? '#EEF8F6' : '#f1f1f1'}
          includeMargin={true}
          size={200}
        />
        <span>
          {isMyWebshop ? 'Denna QR-kod leder till din webbshop' : 'Denna QR-kod leder till webbshoppen'}
        </span>
        <ul className='share-buttons flex items-center'>
          <li><Facebook link={shareLink} /></li>
          <li className='mail'><Mail link={shareLink} /></li>
          <li><a className='sms' href={`sms:?&body=Min%20Webbshop!%20${shareLink}`} title=''>
            <BsChatFill size={24} /><span>SMS</span>
          </a></li>
          <li><a className='sms copy c-pointer' onClick={copyShareLink} title=''>
            <IoIosLink size={24} /><span>{isShareLinkCopied ? 'Kopierad!' : 'Kopiera'}</span>
          </a></li>
        </ul>
      </div>
    </div>
  )
}

export default ShareWebshop
