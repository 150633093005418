import React, { useEffect, useState, } from 'react';
import OrderPayWithSwish from './OrderPayWithSwish';
import { scroller } from 'react-scroll';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { getBuyableBookletsQuery, getBookletQuery } from '../../queries/queries';
import { getAssociationBySlug, getMyUserQuery, createOrderMutation, getTeamBySlug, getTeammemberBySlug, getTeamMembersSafeQuery } from '../../queries/queries';
import { useHistory, useLocation } from 'react-router';
import PersonalWebshopsModal from './PersonalWebshopsModal';
import { useNavigationContext } from '../../context/NavigationContext';
import BookletCard from './BookletCard';
import ShareWebshop from './ShareWebshop';
import { FaRegShareFromSquare } from 'react-icons/fa6';
import BookletView from '../vara-haften/BookletView';
import { GrClose } from 'react-icons/gr';

function Order({ match = { params: {} } }) {
  const { params = {} } = match;
  const history = useHistory();
  const { state } = useLocation();

  const { data: userData } = useQuery(getMyUserQuery);
  const { data: buyableBookletsData } = useQuery(getBuyableBookletsQuery);

  const { data: associationData, loading: associationLoading } = useQuery(getAssociationBySlug, {
    variables: { slug: params.association },
    skip: !params.association
  });
  const { data: teamData, loading: teamLoading } = useQuery(getTeamBySlug, {
    variables: {
      slug: params.team,
      associationSlug: params.association
    },
    skip: !params.team || !params.association
  });
  const { data: teamMemberData, loading: teamMemberLoading } = useQuery(getTeammemberBySlug, {
    variables: {
      slug: params.teammember,
      associationSlug: params.association,
      teamSlug: params.team
    },
    skip: !params.teammember || !params.association || !params.team
  });

  const [getPersonalWebshops, { data: teamMembersData }] = useLazyQuery(getTeamMembersSafeQuery);
  const [getBooklet, { data: bookletData }] = useLazyQuery(getBookletQuery);
  const [createOrder] = useMutation(createOrderMutation);
  const [filteredBooklets, setFilteredBooklets] = useState();

  const [order, setOrder] = useState(null);
  const [webshopError, setWebshopError] = useState(null);
  const [bookletModal, setBookletModal] = useState({ open: false, booklet: null });
  const [selectedBooklet, setSelectedBooklet] = useState(null);
  const [showPersonalShops, setShowPersonalShops] = useState(false);
  const [isShareModal, setIsShareModal] = useState(false);

  const isLoading = associationLoading || teamLoading || teamMemberLoading;

  const { setNavigationActivated } = useNavigationContext();
  useEffect(() => {
    setNavigationActivated(false);
  }, []);

  useEffect(() => {
    window.scroll(0, 0);
  }, [history.location.pathname]);

  // Set webshop error if no association/team/teammember is found
  useEffect(() => {
    if ((associationData && !associationData?.association) || (teamData && !teamData?.team) || (teamMemberData && !teamMemberData?.teamMember)) {
      setWebshopError(true);
    };
  }, [associationData, teamData, teamMemberData]);


  // Show personal webshops modal
  useEffect(() => {
    if (state?.isTeamSelected === undefined && teamData && !teamMemberData && !teamMemberLoading) {
      getPersonalWebshops({ variables: { teamId: teamData?.team?._id } });
      setShowPersonalShops(true);
    } else {
      setShowPersonalShops(false);
    }
  }, [teamData, teamMemberData]);


  useEffect(() => {
    const visibleBooklets = buyableBookletsData?.booklets?.filter(booklet => {
      // Show booklet if purchasableThroughAssociations includes the association id
      if (booklet?.purchasableThroughAssociations?.includes(associationData?.association._id)) {
        return true;
      }
      return false;
    });

    setFilteredBooklets(visibleBooklets);

  }, [buyableBookletsData, associationData]);


  // Get booklet when modal is opened
  useEffect(() => {
    bookletModal.open && getBooklet({ variables: { _id: bookletModal.booklet._id } });
  }, [bookletModal]);


  // Get selected booklet and scroll down to payment section
  useEffect(() => {
    if (order) {
      getBooklet({ variables: { _id: order?.bookletId } });
      setTimeout(() => {
        scroller.scrollTo('payment-step', {
          duration: 800,
        });
      }, 400);
    }
  }, [order]);

  // Create order
  const handleSelectedBooklet = (booklet) => {
    setSelectedBooklet(booklet);
    const vars = {
      variables: {
        bookletId: booklet._id,
        associationId: fixOrderData(associationData?.association?._id),
        teamId: teamData?.team?._id ? fixOrderData(teamData.team?._id) : null,
        teamMemberId: teamMemberData?.teamMember?._id ? fixOrderData(teamMemberData.teamMember?._id) : null,
      },
    };

    createOrder(vars)
      .then(result => setOrder(result && result.data && result.data.createOrder && result.data.createOrder))
      .catch(useMutationError => { console.log('regErr', useMutationError, useMutationError && useMutationError.graphQLErrors) });
  };

  function fixOrderData(Id) {
    //console.log('Id', Id, typeof Id, Id.length);
    if (Id && typeof Id === 'string' && Id.length == 24 && Id != '000000000000000000000000') {
      return Id;
    }
    return null;
  };

  const handleOpenShareModal = () => setIsShareModal(true);
  const handleCloseShareModal = () => setIsShareModal(false);

  return (
    <div id="se-order">
      {/* HEADER */}
      <div className='header'>
        <div className="relative container">
          <button onClick={handleOpenShareModal} className='btn-share-webshop flex items-center'>
            <FaRegShareFromSquare />
            Dela webbshop
          </button>
          {!isLoading && <svg width="50" height="48.75" viewBox="0 0 20 18.75">
            <path
              id="shop"
              d="M3.712,1.438A1.25,1.25,0,0,1,4.662,1H15.338a1.25,1.25,0,0,1,.95.438l3.261,3.8A1.875,1.875,0,0,1,20,6.463v.319A2.969,2.969,0,0,1,14.688,8.6,2.969,2.969,0,0,1,10,8.6a2.969,2.969,0,0,1-4.687,0A2.969,2.969,0,0,1,0,6.781V6.463a1.875,1.875,0,0,1,.451-1.22L3.714,1.436ZM5.937,6.781a1.719,1.719,0,1,0,3.438,0,.625.625,0,1,1,1.25,0,1.719,1.719,0,1,0,3.438,0,.625.625,0,0,1,1.25,0,1.719,1.719,0,1,0,3.438,0V6.463a.625.625,0,0,0-.15-.406L15.338,2.25H4.662L1.4,6.056a.625.625,0,0,0-.15.406v.319a1.719,1.719,0,1,0,3.438,0,.625.625,0,0,1,1.25,0ZM1.875,10.375A.625.625,0,0,1,2.5,11v7.5H3.75V12.25A1.25,1.25,0,0,1,5,11H8.75A1.25,1.25,0,0,1,10,12.25V18.5h7.5V11a.625.625,0,1,1,1.25,0v7.5h.625a.625.625,0,1,1,0,1.25H.625a.625.625,0,1,1,0-1.25H1.25V11A.625.625,0,0,1,1.875,10.375ZM5,18.5H8.75V12.25H5Zm6.25-6.25A1.25,1.25,0,0,1,12.5,11H15a1.25,1.25,0,0,1,1.25,1.25V16A1.25,1.25,0,0,1,15,17.25H12.5A1.25,1.25,0,0,1,11.25,16Zm3.75,0H12.5V16H15Z"
              fill="#222"
            />
          </svg>}
          {!isLoading && !webshopError
            ? <div className='flex flex-col mb'>
              <h1>
                {associationData && teamData && teamMemberData
                  ? <>{teamMemberData?.teamMember?.firstname} {teamMemberData?.teamMember?.lastname}</>
                  : teamData ? teamData?.team?.name
                    : associationData?.association?.name
                }
              </h1>
              {teamData &&
                <h3>
                  {teamMemberData
                    ? <>{teamData?.team?.name}, {associationData?.association?.name}</>
                    : <>{associationData?.association?.name}</>
                  }
                </h3>
              }
            </div>
            : !isLoading && <h1>Hittade ingen webbshop</h1>
          }
        </div>
      </div>

      {!webshopError && !isLoading && <>
        {/* HÄFTEN */}
        <div className="select-booklet">
          <h3>Välj häfte att köpa</h3>
          <div className="container">
            {filteredBooklets?.map(booklet =>
              <BookletCard
                key={booklet._id}
                booklet={booklet}
                setBookletModal={setBookletModal}
                selectedBooklet={selectedBooklet}
                handleSelectedBooklet={handleSelectedBooklet}
              />
            )}
          </div>
        </div>

        {/* BETALNING */}
        {order &&
          <div id="paymentStep" className="payment-step">
            <div className="container">
              <OrderPayWithSwish
                code={order?.code}
                order={order}
                orderId={order?._id}
                email={userData?.user?.email}
                association={associationData ? associationData?.association?.name : null}
                team={teamData ? teamData?.team?.name : null}
                teamMember={teamMemberData ? `${teamMemberData?.teamMember?.firstname} ${teamMemberData?.teamMember?.lastname}` : null}
                booklet={bookletData?.booklet}
              />
            </div>
          </div>
        }


        {bookletModal.open &&
          <div className="show-booklet-modal modal">
            <button className="close" onClick={() => setBookletModal({ open: false, booklet: null })}>
              <GrClose color='black' />
            </button>
            <BookletView isInPopup match={{ params: { booklet: bookletModal.booklet._id } }} />
          </div>
        }
        {showPersonalShops && teamMembersData?.teamMembers?.length > 0 &&
          <PersonalWebshopsModal
            teamMembers={teamMembersData?.teamMembers}
            setShowPersonalShops={setShowPersonalShops}
            association={associationData?.association}
            team={teamData?.team}
            teamSlug={params.team}
          />
        }
        {isShareModal &&
          <ShareWebshop
            associationSlug={params.association}
            teamSlug={params.team}
            teamMemberSlug={params.teammember}
            closeModal={handleCloseShareModal}
          />
        }
      </>}
    </div>
  )
}

export default Order;
