import React, { useState } from 'react';
import { Field } from '../../common';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import Select from 'react-select';
import { getAssociationsQuery } from '../../queries/association';
import { getTeamsSafeQuery } from '../../queries/team';
import { getTeamMembersSafeQuery } from '../../queries/teamMember';
import FieldWrapper from '../../common/FieldWrapper';
import { createCompletedOrdersMutation, completedOrdersQuery } from '../../queries/queries';

const { REACT_APP_BRAND } = process.env;

function BookletGeneratePDF({ booklet }) {
  const { data: associationsData } = useQuery(getAssociationsQuery);
  const [getTeams, { data: teamsData }] = useLazyQuery(getTeamsSafeQuery);
  const [getTeamMembers, { data: teamMembersData }] = useLazyQuery(getTeamMembersSafeQuery);

  const [generateSettings, setGenerateSettings] = useState({
    numberOfValueCoupons: 0,
    selectedAssociation: null,
    selectedTeam: null,
    selectedTeamMember: null,
  });

  const clearSettings = () => {
    setGenerateSettings({
      numberOfValueCoupons: 0,
      selectedAssociation: null,
      selectedTeam: null,
      selectedTeamMember: null,
    });
  };

  const [generateCompletedOrders, { loading: generateLoading, error: generateError }] = useMutation(createCompletedOrdersMutation, {
    onCompleted: () => {
      clearSettings();
    }
  });

  const isPdfSettingsValid = generateSettings.numberOfValueCoupons > 0 && generateSettings.numberOfValueCoupons <= 3000;

  const handleGenerateCompletedOrders = async (e) => {
    e.preventDefault();

    if (!isPdfSettingsValid) {
      alert('Antal värdebevis överstiger maxgränsen på 3000.');
      return;
    }

    try {
      await generateCompletedOrders({
        variables: {
          bookletId: booklet._id,
          amount: generateSettings.numberOfValueCoupons,
          associationId: generateSettings?.selectedAssociation?.value || undefined,
          teamId: generateSettings?.selectedTeam?.value || undefined,
          teamMemberId: generateSettings?.selectedTeamMember?.value || undefined
        },
        refetchQueries: [{ query: completedOrdersQuery, variables: { bookletId: booklet._id } }]
      });
    } catch (err) {
      console.error('Error generating completed orders:', err);
    }
  };

  const ss = (b) => setGenerateSettings({ ...generateSettings, ...b });

  return (
    <>
      <Field
        x2
        type="number"
        name="numberOfValueCoupons"
        title="Antal värdebevis (max 3000) *"
        placeholder='Antal värdebevis'
        value={generateSettings.numberOfValueCoupons}
        onChange={ss}
      />
      <FieldWrapper x2>
        <Select
          isClearable
          options={associationsData?.associations?.map(association => ({ value: association._id, label: association.name }))}
          placeholder={REACT_APP_BRAND === 'CITY' ? 'Välj stad' : 'Välj förening/skola'}
          noOptionsMessage={() => REACT_APP_BRAND === 'CITY' ? 'Hittade ingen stad' : 'Hittade ingen förening/skola'}
          onChange={(association) => {
            setGenerateSettings({
              ...generateSettings,
              selectedAssociation: association ? { value: association.value, label: association.label } : null,
              selectedTeam: null,
              selectedTeamMember: null
            });
            if (association) {
              getTeams({ variables: { associationId: association.value } });
            }
          }}
          value={generateSettings.selectedAssociation}
        />
      </FieldWrapper>
      {generateSettings.selectedAssociation && (
        <FieldWrapper x2>
          <Select
            isClearable
            options={teamsData?.teams?.map(team => ({ value: team._id, label: team.name }))}
            placeholder='Välj lag'
            noOptionsMessage={() => 'Hittade inget lag'}
            onChange={(team) => {
              setGenerateSettings({
                ...generateSettings,
                selectedTeam: team ? { value: team.value, label: team.label } : null,
                selectedTeamMember: null
              });
              if (team) {
                getTeamMembers({ variables: { teamId: team.value } });
              }
            }}
            value={generateSettings.selectedTeam}
          />
        </FieldWrapper>
      )}
      {generateSettings.selectedTeam && (
        <FieldWrapper x2>
          <Select
            isClearable
            options={teamMembersData?.teamMembers?.map(teamMember => ({ value: teamMember._id, label: teamMember.firstname + ' ' + teamMember.lastname }))}
            placeholder='Välj en säljare'
            noOptionsMessage={() => 'Hittade ingen säljare'}
            onChange={(teamMember) => setGenerateSettings({
              ...generateSettings,
              selectedTeamMember: teamMember ? { value: teamMember.value, label: teamMember.label } : null
            })}
            value={generateSettings.selectedTeamMember}
          />
        </FieldWrapper>
      )}
      <div className='block'>
        <button className="primary ml-auto m-0 text-left" onClick={handleGenerateCompletedOrders} disabled={!isPdfSettingsValid || generateLoading}>
          {generateLoading ? 'Genererar värdebevis...' : 'Generera värdebevis'}
        </button>
      </div>
      {generateError && <p>Error: {generateError.message}</p>}
    </>
  );
}

export default BookletGeneratePDF;
