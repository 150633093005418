import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import CountUp from 'react-countup';
import { useQuery } from '@apollo/client';
import { currentPeriodQuery, getPeriodsQuery, getMyUserQuery } from '../queries/queries';
import { useAutosize } from '.';

const { REACT_APP_BRAND } = process.env;
function Number(props) {
  const { value } = props;
  return <CountUp end={value} />;
}

Number.propTypes = {
  value: PropTypes.number,
};

StatsBox.defaultProps = {
  value: 0,
}


function StatsBox(props) {
  const { children, type, title, corner } = props;
  return (
    <div className={`box-sm ${type}`}>
      <header className="stats-box-header">
        <h4>{title}</h4>
        {
          {
            number: <div className='number'><Number value={corner} /></div>,
            string: <div className={`icon ${corner}`} />,
          }[typeof corner]
        }
      </header>
      {
        {
          list: children ? <ul>{children}</ul> : null,
          diagram: children ? <ul>{children}</ul> : null,
          amount: children ? <div>{children}</div> : null,
          number: children ? <div>{children}</div> : null,
        }[type]
      }
    </div>
  );
}

StatsBox.propTypes = {
  children: PropTypes.any,
  type: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  corner: PropTypes.any,
};

StatsBox.defaultProps = {
  children: null,
  corner: null,
}

function StatsListItem(props) {
  const { title, value, subtitle } = props;
  let val = {
    number: <Number value={value} />,
    string: 's' + value,
  }[typeof value] || '...';
  return (
    <li>
      <div className="title">
        <p>{title}</p>
        <p>{subtitle}</p>
      </div>
      <div className="value">{value === null ? '...' : value}</div>
    </li>
  );
}

StatsListItem.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.any,
  value: PropTypes.any,
};

StatsListItem.defaultProps = {
  value: null,
};

function StatsBar(props) {
  const { title, value, percent } = props;
  return (
    <li>
      <div className="value" style={{ height: `${percent * 100}%` }}><span><Number value={value} /></span></div>
      <div className="title">{title}</div>
    </li>
  );
}

StatsBar.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.any.isRequired,
  percent: PropTypes.number.isRequired,
};

function StatsAmount(props) {
  const { value } = props;
  return (
    <span><Number value={value} /> :-</span>
  );
}

StatsAmount.propTypes = {
  value: PropTypes.number,
};

StatsAmount.defaultProps = {
  value: null,
};

function StatsNumber(props) {
  const { value } = props;
  return (
    <span><Number value={value} /> st</span>
  );
}

StatsNumber.propTypes = {
  value: PropTypes.number,
};

StatsNumber.defaultProps = {
  value: null,
};



function StatsSection(props) {
  const { agreementPeriods, currentPeriod, setPeriod, setStatDates, statDates, children } = props;
  const periodButtons = [];
  const periods = agreementPeriods && agreementPeriods.periods;
  let cPeriod = currentPeriod;

  const handleChangeOnInput = (e) => {
    let date = e.target.value;
    let name = e.target.name;

    if (name === 'fromDate') {
      setStatDates({ ...statDates, fromDate: date });
    } else {
      setStatDates({ ...statDates, toDate: date });
    }
  }

  const boxesRef = useRef();
  const [boxes, setBoxes] = useState({ width: undefined, boxes: [] })
  const { width } = useAutosize();

  React.useEffect(() => {
    boxesRef && setBoxes({
      width: boxesRef.current.clientWidth,
      boxes: boxesRef.current.childNodes.length
    });
  }, [width])

  const possessionType = JSON.parse(localStorage.getItem('POSSESSION'))?.type;
  const { data: userData } = useQuery(getMyUserQuery);
  const { data: currentData } = useQuery(currentPeriodQuery);
  const { data: periodsData } = useQuery(getPeriodsQuery);

  const currentPeriodData = periodsData?.periods.find(period => period.period === currentPeriod);

  // if (periods && !currentPeriod) {
  //   cPeriod = periods.currentPeriod;
  // }
  // if (periods) {
  //   for (let i = periods.fromPeriod; i <= periods.toPeriod; i += 1) {
  //     periodButtons.push(<button key={i} className={`${cPeriod === i ? 'selected ' : ''}tab`} type="button" onClick={() => setPeriod(i)}><SeasonById id={i} /></button>);
  //   }
  //   // periodButtons.push(<button key="all" className={`${cPeriod === 'all' ? 'selected ' : ''}tab`} type="button" onClick={() => setPeriod('all')}>Totalt</button>);    
  // }
  const seasonYear = (from, to) => {
    const fromYear = new Date(from).getFullYear();
    const toYear = new Date(to).getFullYear();
    if (!isNaN(fromYear) && !isNaN(toYear)) {
      if (toYear === fromYear) {
        return `${fromYear.toString().substring(2)}`
      }
      else {
        return `${fromYear.toString().substring(2)}/${toYear.toString().substring(2)}`
      }
    }
  }

  return (
    <div className="statistics">
      <div className={`green-bg ${REACT_APP_BRAND === 'CITY' ? 'cityhaftet' : ''}`} />
      <div className="container">
        <div className="stat-dates">
          <form className="stat-form stat-form-general">
            <p>Välj datum för att se statistik för specifikt tidsintervall</p>
            <div>
              <label htmlFor="fromDate">Från</label>
              <input type="date" id="fromDate" name="fromDate" onChange={handleChangeOnInput} />
            </div>
            <div>
              <label htmlFor="toDate">Till</label>
              <input type="date" id="toDate" name="toDate" min={statDates?.fromDate && statDates?.fromDate} onChange={handleChangeOnInput} />
            </div>
          </form>
        </div>
        <div className={`boxes ${boxes.width < width && boxes.boxes === 4 ? 'center' : ''}`}>
          <div ref={boxesRef} className={`inline-boxes ${boxes.width < width && boxes.boxes === 4 ? 'center' : ''}`}>
            {children}
          </div>
        </div>
        {/* {REACT_APP_BRAND === 'SAENKELT' ?
          <>
            <div class="stat-dates">
              <form className="stat-form stat-form-general">
                <p>Välj datum för att se statistik för specifikt tidsintervall</p>
                <div>
                  <label for="fromDate">Från</label>
                  <input type="date" id="fromDate" name="fromDate" onChange={handleChangeOnInput} />
                </div>
                <div>
                  <label for="toDate">Till</label>
                  <input type="date" id="toDate" name="toDate" min={statDates.fromDate && statDates.fromDate} onChange={handleChangeOnInput} />
                </div>
              </form>
            </div>
            <div className={`boxes ${boxes.width < width && boxes.boxes === 4 ? 'center' : ''}`}>
              <div ref={boxesRef} className={`inline-boxes ${boxes.width < width && boxes.boxes === 4 ? 'center' : ''}`}>
                {children}
              </div>
            </div>
          </>
          :
          <>
            <div className="timeline">
              <div className="from"><SeasonById id={cPeriod === 'all' ? periods.fromPeriod : cPeriod} valName="startDate" date excludeYear /></div>
              <div className="timeline-flex">
                <div className="dots"></div>
                <div className="select-period-center">
                  {(userData?.user?.superAdmin || possessionType === 'association') && periodsData && currentData &&
                    <button onClick={() => setPeriod(cPeriod - 1)} disabled={cPeriod === periodsData?.periods[0].period}>
                      <img width={15} height={15} src={previous} alt='' />
                    </button>
                  }
                  <span className='period'>{currentPeriodData?.seasonName} {seasonYear(currentPeriodData?.startDate, currentPeriodData?.endDate)}</span>
                  {(userData?.user?.superAdmin || possessionType === 'association') && periodsData && currentData &&
                    <button onClick={() => setPeriod(cPeriod + 1)} disabled={cPeriod === currentData.period.period}>
                      <img width={15} height={15} src={next} alt='' />
                    </button>
                  }
                </div>
                <div className="dots"></div>
              </div>
              <div className="to"><SeasonById id={cPeriod === 'all' ? periods.toPeriod : cPeriod} valName="endDate" date excludeYear /></div>
            </div>
            <div className={`boxes ${boxes.width < width && boxes.boxes === 4 ? 'center' : ''}`}>
              <div ref={boxesRef} className={`inline-boxes ${boxes.width < width && boxes.boxes === 4 ? 'center' : ''}`}>
                {children}
              </div>
            </div>
          </>
        } */}

      </div>
    </div>
  );
}

StatsSection.propTypes = {
  agreementPeriods: PropTypes.object,
  currentPeriod: PropTypes.any,
  setPeriod: PropTypes.func.isRequired,
  children: PropTypes.any,
};

StatsSection.defaultProps = {
  agreementPeriods: null,
  currentPeriod: null,
};

export { StatsSection, StatsBox, StatsListItem, StatsBar, StatsAmount, StatsNumber };
